<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton as="div">
      <button ref="menuButton" class="flex items-center">
        <span class="text-sm font-semibold leading-6 text-sky-600">{{ buttonString }}</span>
        <ChevronDownIcon v-if="addIcon" class="h-5 w-5" aria-hidden="true" />
      </button>
    </MenuButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems ref="menuItems" class="fixed z-[1000] w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-[200px]">
        <div class="py-1">
          <MenuItem v-for="details in dropdownItems" :key="details.name" v-slot="{ active }">
            <a @click="handleClick(details)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ details }}</a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
  name: "SimpleDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon
  },
  props: {
    buttonString: String,
    dropdownItems: Array,
    addIcon: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['item-clicked'],
  setup(props, { emit }) {
    const menuButton = ref(null);
    const menuItems = ref(null);

    const positionDropdown = () => {
      setTimeout(() => {
        const button = menuButton.value.$el;
        const dropdown = menuItems.value.$el;
        if (button && dropdown) {
          const rect = button.getBoundingClientRect();
          dropdown.style.top = `${rect.bottom + window.scrollY}px`;
          dropdown.style.left = `${rect.left + window.scrollX}px`;
        }
      }, 0);
    };

    const handleClick = (details) => {
      emit('item-clicked', details);
    };

    onMounted(() => {
      positionDropdown();
      window.addEventListener('resize', positionDropdown);
      window.addEventListener('scroll', positionDropdown, true);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', positionDropdown);
      window.removeEventListener('scroll', positionDropdown, true);
    });

    return {
      menuButton,
      menuItems,
      handleClick,
      positionDropdown
    };
  },
};
</script>

