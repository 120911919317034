function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function isWithinRadius(coord1, coord2, radius) {
    const R = 6371; // radius of Earth in km

    const phi1 = toRadians(coord1.lat);
    const phi2 = toRadians(coord2.lat);
    const dphi = toRadians(coord2.lat - coord1.lat);
    const dlambda = toRadians(coord2.lng - coord1.lng);

    const a = Math.sin(dphi / 2) ** 2 +
        Math.cos(phi1) * Math.cos(phi2) * Math.sin(dlambda / 2) ** 2;

    const calculatedRadius = 2 * R * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    if (calculatedRadius <= radius) {
        return true; //coordinates are within radius of eachouther
    } else {
        return false; //coordinates are farther apart
    }
}

export { isWithinRadius };