<template>
    <div class="flex items-center bg-color-f dark:bg-color-f-darkmode rounded-lg p-[10px] m-[10px] text-left w-[96%] bg-color cursor-pointer">
        <div class="ml-[10px] content-center w-full">
            <h2 v-if="friendlyName" class="underline text-xl font-bold dark:text-dark-text">{{ friendlyName }}</h2>
            <p class="dark:text-dark-text"><span class="font-bold">Data ID: </span>{{ dataID }}</p>
            <p class="dark:text-dark-text"><span style="font-weight: bold;">Last Acvive: </span>{{ lastActivity }}</p>
        </div>
        <div class="p-5 rounded-full border-4 h-fit" :style="{ 'border-color': borderColor }">
            <img class="w-12" src="@/assets/unit.png" alt="unit">
        </div>
    </div>
</template>
    
<script>
    import { ref, onMounted } from 'vue';
    import { utcToLocal, timeSimplified } from '@/tools/TimeFunctions';

    export default {
        name: 'UnitCard',
        components: {
        },
        props: {
            unitData: Object,
            dataID: String,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },
        setup(props) {
            const friendlyName = ref(null);
            const lastActivity = ref(null);
            const borderColor = ref('black')

            onMounted(() => {
                friendlyName.value = props.unitData.friendlyName;
                lastActivity.value = timeSimplified(utcToLocal(props.unitData.lastActivityUTC, props.timeFormat)) || "no activity";

                if (lastActivity.value === 'Current') {
                    borderColor.value = 'green';
                }
                else if (lastActivity.value === 'no activity') {
                    borderColor.value = 'gray';
                }
                else {
                    borderColor.value = 'red';
                }
            });

            return {
                friendlyName,
                lastActivity,
                borderColor,
            };
        },
    }
</script>

<style>

</style>