<template>
  <div class="login-background" >
    <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <!-- <img class="mx-auto h-10 w-auto" src="https://firebasestorage.googleapis.com/v0/b/mesh-db-stg.appspot.com/o/icons%2Fmeshcomm-icon.png?alt=media&token=44dedc55-ef54-4256-a204-de8cd84829db" alt="Meshportal icon" /> -->
        <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">Reset Password</h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <p class="text text-gray-500">Enter the email associated with your account</p>
          <br>
          <form class="space-y-6" @submit.prevent="resetPassword">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 text-gray-600">Email address</label>
              <div class="mt-2">
                <input id="email" name="email" type="email" autocomplete="email" v-model="email" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <p id="message" :ref="message" v-if="message" class="font-semibold text-green-600 text-sm">{{ message }}</p>

            <div>
              <button id="sendEmail" type="submit" :disabled="isLoading" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ isLoading ? 'Sending email...' : 'Reset password' }}</button>
              <br>
              <button id="returnLogin" type="button" @click="login" class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-400 border-indigo-400 border-2 shadow-sm hover:bg-indigo-100">Back to login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref } from 'vue';
import { router } from '@/router';
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '@/firebase';

export default {
  name: 'ResetPassword',
  setup() {
    const email = ref('');
    const message = ref(null);
    const isLoading = ref(false);

    const resetPassword = async () => {
      isLoading.value = true;
      await sendPasswordResetEmail(auth, email.value)
      .then(() => {
        message.value = "An email was sent";
      })
      .catch ((error) => {
        message.value = error.code;
      });
      isLoading.value = false;
    };

    const login = () => {
      router.push({ name: 'Dashboard' });
    };

    return {
      email,
      message,
      isLoading,
      resetPassword,
      login
    };
  },
};
</script>

<style>
.login-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
  background-image: url('@/assets/MeshComm-Hero-Area-SIGNAL.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
