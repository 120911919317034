<template>
    <div class="bg-color-f rounded-md p-3">
        <div class="flex flex-row justify-between">
            <div>
                <h1 class="mt-0 mb-3 text-2xl">{{ template.title }}</h1>
                <div class="flex items-center mb-1">
                    <DatePicker v-model="dateValue" class="w-[420px] mr-5"></DatePicker>
                    <button v-if="!rangeSet" :disabled="loading === 'setRange'" @click="setRange" :class="{ 'bg-color-a-lighter': loading === 'setRange' }" class="default-button">Set Range</button>
                    <button v-else @click="currentEvents" class="default-button">Back To Current</button>
                    <LoadingAnimation1 v-if="loading === 'setRange'" class="mx-5"></LoadingAnimation1>
                </div>
            </div>
            <LoadingAnimation1 v-if="loading === 'delete'" class="mx-5"></LoadingAnimation1>
            <TrashIcon v-else class="w-6 h-6 hover:cursor-pointer" @click="deleteTable"/>
        </div>
        <br>
        <div v-if="!rangeSet" class="flex flex-row justify-between h-10">
            <p>Most Recent Events</p>
            <div>
                <button v-if="checkedRows.length && loading !== 'updateTag'" @click="updateTag('add')" class="default-button h-8 mr-2">Add/Update Tag</button>
                <button v-if="checkedRows.length && loading !== 'updateTag'" @click="updateTag('remove')" class="default-button h-8">Remove Tag</button>
                <LoadingAnimation1 v-if="loading === 'updateTag'" class="mx-5"></LoadingAnimation1>
            </div>
        </div>
        <table class="w-full bg-white border-collapse whitespace-nowrap">
            <thead>
                <tr>
                    <th class="bg-sky-600 w-0"><input type="checkbox" @change="toggleAll"></th>
                    <th v-for="column in tableData.keys()" :key="column" class=" bg-sky-600 p-2 text-left min-w-32">{{ column }}</th>
                </tr>
            </thead>
            <tbody v-if="tableData.get('Start Timestamp').length">
                <tr v-for="index in tableData.get('Start Timestamp').length" :key="index" :class="{'bg-slate-200': index % 2 !== 0}" class="h-10">
                    <td class="w-[25px]"><input type="checkbox" @change="toggleRow(index-1)" :checked="checkedRows.includes(index-1)"></td>
                    <td v-for="value in tableData.values()" :key="value[index-1]">{{ value[index-1] }}</td>
                </tr>
            </tbody>

            <p v-else class="m-5">No events found</p>
        </table>
    </div>
</template>

<script>
/* eslint-disable vue/no-setup-props-destructure */
    import { onMounted, ref, onUnmounted } from 'vue';
    import { collection, orderBy, query, where, getDocs, onSnapshot } from 'firebase/firestore';
    import { db } from '@/firebase';
    import { TrashIcon } from '@heroicons/vue/24/solid';
    import { useStore } from 'vuex';

    import { findDifference, getPastTimestamp, utcToLocal, localToUTC } from '@/tools/TimeFunctions';
    import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
    import DatePicker from '../dropdowns/DatePicker.vue';

    export default {
        name: 'EventsTable',
        components: {
            LoadingAnimation1,
            DatePicker,
            TrashIcon
        },
        props: {
            dataID: String,
            tableIndex: Number,
            template: Object,
            templateID: String,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },
        emits: ['tableDeleted'],
        setup(props, { emit }) {

            const tableData = ref(new Map([
                ['Start Timestamp', []],
                ['End Timestamp', []],
                ['Elapsed Time', []],
                ['Tag', []],
            ]));
            const dateValue = ref([]);
            const rangeSet = ref(false);
            const loading = ref(null);
            const checkedRows = ref([]);
            const menu = ref(null);

            let checkAll = false;
            let unsubscribe;

            const store = useStore();
            const user = store.state.user;

            const operators = {
                '>'  : (a, b) => a > b,
                '>=' : (a, b) => a >= b,
                '<'  : (a, b) => a < b,
                '<=' : (a, b) => a <= b,
                '==' : (a, b) => a == b,
                '!=' : (a, b) => a != b,
            };

            /**
             * Grab data from the data collection
             */
             const currentEvents = onMounted(async() => {
                // clear the table.
                tableData.value.forEach((value, key) => {
                    tableData.value.set(key, []);
                });

                rangeSet.value = false;

                // Create a query for the last 24 hours of events
                const q = query(
                    collection(db, 'data', props.dataID, props.template.source),
                    where('messageUTC', '>=', getPastTimestamp(24)),
                    orderBy('messageUTC', 'asc')
                );

                // Set up the snapshot listener
                unsubscribe = onSnapshot(q, (querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        generateEvents(change.doc.data());
                    }
                    // You can also handle "modified" and "removed" cases if needed
                    });
                });
            });

            // Clean up the listener when the component is unmounted
            onUnmounted(() => {
                unsubscribe();
            });

            /**
             * Group data together based on the type of event, and wether its a previous event
             * or current.
             */
            const generateEvents = (document) => {
                // Check if the document meets the event criteria.
                const value = document[props.template.field];
                const operatorFunction = operators[props.template.operator];
                if(!operatorFunction(value, props.template.constant)) {
                    return;
                }
                

                // check how far appart each message is in seconds.
                const timeDifference = findDifference(tableData.value.get('End Timestamp')[0], utcToLocal(document.messageUTC, props.timeFormat))[1];

                // Start a new event if the time difference is greater than 65 seconds, or if the time difference is undefined (first event)
                if (timeDifference > 65 || !timeDifference) {
                    // Set the start timestamp to the current timestamp
                    tableData.value.get('Start Timestamp').unshift(utcToLocal(document.messageUTC, props.timeFormat));

                    // Set the end timestamp to 30 seconds after the start timestamp
                    let endTimestamp = new Date(document.messageUTC);
                    endTimestamp.setSeconds(endTimestamp.getSeconds() + 30);
                    endTimestamp = endTimestamp.toISOString();
                    tableData.value.get('End Timestamp').unshift(utcToLocal(endTimestamp, props.timeFormat));

                    // Set the elapsed time to 30 seconds
                    tableData.value.get('Elapsed Time').unshift('0 day(s) 00:00:30');
                    tableData.value.get('Tag').unshift(document.tag || '');

                    // Limit the number of events to 10
                    if (tableData.value.get('Start Timestamp').length > 10) {
                        tableData.value.get('Start Timestamp').pop();
                        tableData.value.get('End Timestamp').pop();
                        tableData.value.get('Elapsed Time').pop();
                        tableData.value.get('Tag').pop();
                    }
                }


                // Continue building on an event
                else {
                    tableData.value.get('End Timestamp')[0] = utcToLocal(document.messageUTC, props.timeFormat);
                    tableData.value.get('Elapsed Time')[0] = findDifference(tableData.value.get('Start Timestamp')[0], tableData.value.get('End Timestamp')[0])[0];
                }
            };

            /**
             * Sets the time range of which documents to grab
             */
            const setRange = async() => {
                // Start the loading animation
                loading.value = 'setRange';

                // clear the table.
                tableData.value.forEach((value, key) => {
                    tableData.value.set(key, []);
                });

                //Grab the utc start and end date.
                const utcStart = localToUTC(dateValue.value[0]);
                const utcEnd =  localToUTC(dateValue.value[1]);

                // Grab documents within set dates. 
                const q = query(collection(db, 'data', props.dataID, props.template.source), where('messageUTC', '>=', utcStart), where('messageUTC', '<=', utcEnd), orderBy('messageUTC', 'asc'));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    generateEvents(doc.data());
                });

                // Stop Loading animation and show "back to current" button.
                loading.value = null;
                rangeSet.value = true;
            };

            const toggleRow = (dataIndex) => {
                const index = checkedRows.value.indexOf(dataIndex);
                if (index === -1) {
                    checkedRows.value.push(dataIndex);
                } else if (index === 0) {
                    checkedRows.value.splice(0, 1);
                } else {
                    checkedRows.value.splice(index, index);
                }
            };

            const toggleAll = () => {
                checkAll = !checkAll;

                if (checkAll) {
                    // const length = tableData.value.get('Start Timestamp').length;
                    const length = 10;
                    for (let dataIndex = 0; dataIndex < length; dataIndex++) {
                        checkedRows.value.push(dataIndex);
                    }
                } else {
                    checkedRows.value = [];
                }
            }

            const updateTag = async (action) => {
                let tag;
                if (action === 'add') {
                    tag = prompt("Enter tag for selected events:");
                    if (!tag) return;
                } else {
                    tag = '';
                }

                loading.value = 'updateTag';
                const body = {
                    uid: user.uid,
                    dataID: props.dataID,
                    eventDetails: [],
                };

                for (const index of checkedRows.value) {
                    const eventDetails = {
                        tag: tag,
                        collection: props.template.source,
                        start: localToUTC(tableData.value.get('Start Timestamp')[index], props.timeFormat),
                        end: localToUTC(tableData.value.get('End Timestamp')[index], props.timeFormat),
                    };
                    body.eventDetails.push(eventDetails);
                }

                console.log(body);

                let responseData;
                try {
                    // Make the DELETE API call
                    const response = await fetch(`https://us-central1-mesh-db-stg.cloudfunctions.net/events`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;

                if (responseData.code === 200) {
                    for (const index of checkedRows.value) {
                        tableData.value.get('Tag')[index] = tag;
                }
                } else {
                    try {
                        alert(responseData.message);
                    } catch (error) {
                        alert("Something went wrong when creating the entity");
                    }
                }
            };

            const deleteTable = async() => {
                loading.value = 'delete';

                let responseData;
                try {
                    const body = {
                        uid: user.uid,
                        templateID: props.templateID,
                        dashboard: 'events',
                        widgetType: 'tables',
                        widgetID: props.tableIndex,
                    };

                    // Make the DELETE API call
                    const response = await fetch(`https://us-central1-mesh-db-stg.cloudfunctions.net/templates`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;

                if (responseData.code === 200) {
                    emit('tableDeleted', props.tableIndex);
                } else {
                    try {
                        alert(responseData.message);
                        console.log(responseData);
                    } catch (error) {
                        alert("Something went wrong when creating the entity");
                    }
                }
            };

            return {
                tableData,
                dateValue,
                rangeSet,
                loading,
                checkedRows,
                currentEvents,
                menu,
                setRange,
                toggleRow,
                toggleAll,
                updateTag,
                deleteTable,
            };
        },
    };
</script>

<!-- <style>
    table {
    width: 100%;
    background-color: white;
    border-collapse: collapse;
    }

    th {
        background-color: rgb(48, 145, 255);
    }

    th, td {
    /* border: 1px solid black; */
    box-sizing: border-box;
    padding: 8px;
    text-align: left;
    width: 14.29%;
    }

    tr {
        height: 35px;
        width: 100%;
        display: table;
        /* table-layout: fixed; */
    }

    .run-and-charge-container {
        height: auto;
        max-height: 575px;
        /* overflow: hidden; */
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .alternate-color {
        background-color: rgb(211, 211, 211);
    }
</style> -->