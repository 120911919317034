<template>
    <div class="unit-map-container">
        <p><span class="font-bold">Last Update: </span>{{ lastUpdate }}</p>
        <div class="flex items-center mb-2">
            <DatePicker v-model="dateValue" class="w-[450px] mr-5"></DatePicker>
            <button :disabled="loading" @click="generatePolyline" class="default-button">View Location History</button>
            <button v-if="showPolyline"  @click="grabLocation" class="default-button ml-2">Back to Current Location</button>
            <LoadingAnimation1 v-if="loading" class="mx-5"></LoadingAnimation1>
        </div>
        <div class="unit-google-map" ref="mapContainer"></div>
        <div ref="control" class="map-control">
            <button v-if="!showPolyline" @click="recenterMap">Recenter</button>
        </div>
    </div>
</template>
    
<script>
    import { onMounted, ref } from 'vue';
    import { onSnapshot, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
    import { db } from '@/firebase';
    import { timeSimplified, localToUTC, utcToLocal } from '@/tools/TimeFunctions';
    import { isWithinRadius } from '@/tools/Geofence';
    import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
    import DatePicker from '../dropdowns/DatePicker.vue';
    

    //Set up Google Maps
    (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise((f,n)=>{a=m.createElement("script");e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once."):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        key: "AIzaSyAdnZ7SDSCwfssmTuoztLkglh9w06vbPg8",
        v: "weekly",
    });

    /* global google */
    export default {
        name: 'UnitMap',
        components: {
            LoadingAnimation1,
            DatePicker
        },
        props: {
            dataID: String,
            template: Object,
            timeFormat: {
                type: String,
                default: '24hour'
            },
        },
        setup(props) {
            //Set up variable references.
            const mapContainer = ref(null);
            const showPolyline = ref(false);
            const dateValue = ref([]);
            const control = ref(null);
            const loading = ref(false);
            const lastUpdate = ref('');

            //Set other global variables.
            let map;
            let marker;
            let bounds;
            let polyline;
            let unsubscribe;
            let infoWindow;
            let center = true;
            const batchSize = 1000; // DONT CHANGE
            const geofenceRadius = 0.01; // Kilometers

            /**
             * Initialize google maps
             */
            onMounted(async() => {
                const { Map, InfoWindow } = await google.maps.importLibrary("maps");
                map = new Map(mapContainer.value, {
                    center: new google.maps.LatLng(39.22696383208982, -98.91715880586187),
                    zoom: 5,
                    mapId: "Meshportal Map",
                    mapTypeId: google.maps.MapTypeId.HYBRID,
                });

                // Initiate the info window for markers
                infoWindow = new InfoWindow({
                    content: "",
                    disableAutoPan: true,
                });

                // Close the info window if clicked off on the map
                google.maps.event.addListener(map, "click", () => {
                    if (infoWindow) {
                        infoWindow.close();
                    }
                });

                // If the user changed the zoom, stop centering
                google.maps.event.addListener(map, 'zoom_changed', () => {
                    center = false;
                });

                // If the user changed the zoom, stop centering
                google.maps.event.addListener(map, 'dragstart', () => {
                    center = false;
                });

                // Add a recenter button to the map
                const controlDiv = document.createElement('div');
                controlDiv.appendChild(control.value);
                map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);

                bounds = new google.maps.LatLngBounds();

                grabLocation();
            });

            /**
             * Continually grabs the current coordinates of the unit.
             */
            const grabLocation = async () => {
                const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

                // Remove poliline if it exists
                if (polyline) {
                    polyline.setMap(null);
                    polyline = null;
                }
                showPolyline.value = false

                const greenMarker = new PinElement({
                    background: '#00ff00',
                    borderColor: '#00ff00',
                    glyphColor: "#0AA900"
                })

                // Start a snapshot listener
                const subcollectionRef = collection(db, 'data', props.dataID, props.template.source);
                const q = query(subcollectionRef, orderBy('messageUTC', 'desc'), limit(1));
                unsubscribe = onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const docData = change.doc.data();

                        // Make sure coordinates exist
                        if (docData[props.template.latitudeField]){

                            lastUpdate.value = docData.messageUTC;

                            const coordinate = {
                                lat: docData[props.template.latitudeField],
                                lng: docData[props.template.longitudeField],
                                alt: docData[props.template.altitudeField],
                            };

                            // Update the marker if it already exists
                            if (marker) {
                                marker.position = coordinate;
                            } 
                            
                            // Initiate the marker
                            else {
                                marker = new AdvancedMarkerElement({
                                    position: coordinate,
                                    map: map,
                                });

                                marker.addListener("click", () => {
                                    if(coordinate.alt) {
                                        infoWindow.setContent(`
                                            <span class="font-bold">Altitude: </span>${coordinate.alt}<br>
                                            <span class="font-bold">Latitude: </span>${coordinate.lat}<br>
                                            <span class="font-bold">Longitude: </span>${coordinate.lng}
                                        `);
                                    } else{
                                        infoWindow.setContent(`
                                            <span class="font-bold">Latitude: </span>${coordinate.lat}<br>
                                            <span class="font-bold">Longitude: </span>${coordinate.lng}
                                        `);
                                    }
                                    infoWindow.open(map, marker);
                                });
                            }

                            if (timeSimplified(utcToLocal(docData.messageUTC)) === 'Current'){
                                marker.content = greenMarker.element;
                            } else {
                                marker.content = null;
                            }

                            // If there was no human interaction with the map, then continue to
                            // recenter over the marker.
                            if (center) {
                                recenterMap();
                            }
                        }
                    });
                });
            };

            /**
             * reset the view position over the marker.
             */
            const recenterMap = () => {
                map.setCenter(marker.position);
                map.setZoom(18);
                center = true;
            };

            /**
             * generates a polyline of historical location.
             */
             const generatePolyline = async() => {
                loading.value = true;

                // Remove poliline if it exists
                if (polyline) {
                    showPolyline.value = false;
                    polyline.setMap(null);
                    polyline = null;
                }

                //Stop live location snapshot
                if(marker) {
                    unsubscribe();
                    marker.setMap(null);
                    marker = null;
                    bounds = new google.maps.LatLngBounds();
                }

                //Grab the utc start and end date.
                const utcStart = localToUTC(dateValue.value[0]);
                const utcEnd =  localToUTC(dateValue.value[1]);

                //set up initial variables
                let polylineCoords = [];
                let size = 0;
                let lastDoc = null;
                let previousCoord = {lat: 0, lng: 0};
                let q;

                //Querry historical locations from database.
                do {
                    if (lastDoc) {
                        q = query(
                            collection(db, 'data', props.dataID, 'location'),
                            where('messageUTC', '>=', utcStart),
                            where('messageUTC', '<=', utcEnd),
                            orderBy('messageUTC'),
                            limit(batchSize),
                            startAfter(lastDoc)
                        );
                    } else {
                        q = query(
                            collection(db, 'data', props.dataID, 'location'),
                            where('messageUTC', '>=', utcStart),
                            where('messageUTC', '<=', utcEnd),
                            orderBy('messageUTC'),
                            limit(batchSize)
                        );
                    }
                    const querySnapshot = await getDocs(q);
                    
                    //save the size of the snapshot and the last document retrieved.
                    size = querySnapshot.size;
                    lastDoc = querySnapshot.docs[size - 1];

                    //start storing the coordinates
                    querySnapshot.forEach((document) => {
                        const data = document.data();
                        const newCoord = {lat: data.lat, lng: data.lon};
                        if (previousCoord && !isWithinRadius(previousCoord, newCoord, geofenceRadius)) {
                            polylineCoords.push(newCoord);
                            previousCoord = newCoord;
                            bounds.extend(newCoord);
                        }
                    });

                } while (size >= batchSize);

                polyline = new google.maps.Polyline({
                    path: polylineCoords,
                    geodesic: true,
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });

                polyline.setMap(map);
                map.fitBounds(bounds);
                loading.value = false;
                showPolyline.value = true;
            };

            return {
                mapContainer,
                showPolyline,
                dateValue,
                control,
                loading,
                lastUpdate,
                recenterMap,
                generatePolyline,
                grabLocation
            }
        },
    };
</script>
    
<style>
    .unit-map-container {
        background-color: white;
        padding: 10px;
        height: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }

    .map-date-container {
        display: flex;
        margin-bottom: 10px;
    }

    .map-date-container div {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }

    .unit-google-map {
        flex-grow: 1;
    }
</style>