<template>
    <DefaultLayout
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    :subpageLinks="subpageLinks"
    >
        <!-- Add this toggle switch below the chart container -->
        <div class="flex items-center justify-between">
            <button @click="handleAddChart" class="default-button m-5">+ Add Chart</button>
            <div class="flex items-center justify-between mr-3">
                <div>
                    <span class="mr-3">Vertical Line</span>
                    <label class="relative inline-block w-[60px] h-[34px]">
                        <input class="opacity-0 w-0 h-0 peer" type="checkbox" v-model="showVerticalLine">
                        <span class="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 duration-200 before:absolute before:content-[''] before:h-[26px] before:w-[26px] before:left-[4px] before:bottom-[4px] before:bg-white before:duration-200 rounded-[34px] before:rounded-[50%] peer-checked:before:transform peer-checked:bg-blue-500 peer-checked:before:translate-x-[26px]"></span>
                    </label>
                </div>
                <button id="downloadButton" class="default-button ml-5" @click="requestDataDownload">
                    <ArrowDownTrayIcon class="w-5 h-5" />
                </button>
            </div>
        </div>
        <div class="flex">
            <DatePicker v-model="customTimeRange" class="mb-5 w-[450px]" />
            <button class="default-button h-[40px] ml-5" @click="applyCustomTimeRange">Apply</button>
            <button v-if="customTimeRange.length > 0" class="default-button h-[40px] ml-5" @click="setLiveData">Back to Live</button>
        </div>
        <div class="relative w-[calc(100%-30px)]" ref="chartContainer">
            <div class="flex flex-col flex-grow">
                <TimeSeries v-for="(template, index) of charts" 
                    :key="`timeseries-${index}`"
                    :index="index"
                    :page="'timeSeries'"
                    :templateID="unitDetails.defaultTemplate"
                    :template="template"
                    :dataID="dataID"
                    :timeRange="timeRange"
                    :live="liveUpdate"
                    @newTimeStamp="updateTimeRange"
                    @delete="deleteTimeSeries(index)"
                    @modal="handleModal"
                    @dataRequested="handleDataRequest"
                    ref="timeSeriesRefs"
                ></TimeSeries>
            </div>
            <!-- Move the vertical line outside the flex container and use visibility instead of display -->
            <div 
                ref="verticalLine" 
                class="absolute h-full w-[3px] bg-black pointer-events-none z-[9999] top-0"
                :style="{ 
                    visibility: showVerticalLine ? 'visible' : 'hidden',
                    opacity: showVerticalLine ? 1 : 0
                }"
            ></div>
        </div>
        <button @click="handleAddChart" class="default-button m-5">+ Add Chart</button>
        <UpdateTemplate v-if="modal === 'UpdateTemplate'"
            :templateID="unitDetails.defaultTemplate"
            :uid="uid"
            :dataID="dataID"
            :widget="'charts'"
            :page="'timeSeries'"
            @close="closeModal"
            @widget="addTimeSeries"
            @error="error"
        ></UpdateTemplate>
    </DefaultLayout>
</template>
    
<script>
    import { useStore } from 'vuex';
    import { onMounted, onUnmounted, ref, nextTick } from 'vue';
    import { router } from '@/router';
    import { doc, getDoc, onSnapshot } from 'firebase/firestore';
    import { db } from '@/firebase';
    import { utcToLocal, timeSimplified, getPastTimestamp, dateToString } from '@/tools/TimeFunctions';
    import { ArrowDownTrayIcon } from '@heroicons/vue/24/solid';

    import DefaultLayout from '@/layouts/Layout.vue';
    import TimeSeries from '@/components/charts/TimeSeries.vue'
    import UpdateTemplate from '@/components/modals/updateTemplate.vue';
    import DatePicker from '@/components/dropdowns/DatePicker.vue';
    
    export default {
        name: 'UnitDashboardTimeseries',
        components: {
            DefaultLayout,
            TimeSeries,
            UpdateTemplate,
            ArrowDownTrayIcon,
            DatePicker
        },
        setup() {
            // Set up reference variables.
            const pageTitle = ref('');
            const pageSubtitle = ref('');
            const subpageLinks = ref(new Map());
            const charts = ref([]);
            const timeRange = ref([getPastTimestamp(24, 'local'), dateToString(new Date())]);
            const customTimeRange = ref([]);
            const liveUpdate = ref(true);
            const modal = ref(null);
            const unitDetails = ref(null);
            const timeSeriesRefs = ref([]);

            // Set up all other variabless
            const store = useStore();
            const timeFormat = store.state.user.timeFormat;
            const uid = store.state.user.uid;
            const dataID = router.currentRoute.value.params.dataID;

            const headers = ['Timestamp'];
            const allData = {};
            let dataResponses = 0;
            let dataRequests = 0;

            const verticalLine = ref(null);
            const chartContainer = ref(null);
            const leftBoundary = 100;
            const rightBoundary = 85;
            const isLineFrozen = ref(false);
            const frozenPosition = ref(null);
            const showVerticalLine = ref(true);

            const previousVerticalLineState = ref(showVerticalLine.value);

            const handleAddChart = () => {
                previousVerticalLineState.value = showVerticalLine.value;
                modal.value = 'UpdateTemplate';
                showVerticalLine.value = false;
                isLineFrozen.value = false;
                frozenPosition.value = null;
            };

            const handleModal = (modalState) => {
                if (modalState) {
                    previousVerticalLineState.value = showVerticalLine.value;
                    showVerticalLine.value = false;
                    isLineFrozen.value = false;
                    frozenPosition.value = null;
                } else {
                    modal.value = null;
                    showVerticalLine.value = previousVerticalLineState.value;
                }
            };

            onMounted(async () => {
                await getUnitDetails();
                getTemplate();
                checkActivity();
            });

            /**
             * Grabs the unit details and sets up the page title
             */
             const getUnitDetails = async() => {
                
                // Attempt the data document using the data ID
                try {
                    const docRef = doc(db, "data", dataID);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        unitDetails.value = docSnap.data();
                    }
                } catch(error) {
                    console.log(error)
                    pageTitle.value = dataID;
                    pageSubtitle.value = "Faild to retrieve data";
                }
            };

            /**
             * Grabs the template for the unit and adds additional subpage links
             */
            const getTemplate = async() => {

                // The template is not stored so grab it from the database.
                try {
                    const docRef = doc(db, "templates", unitDetails.value.defaultTemplate);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Grab the document data and store it
                        const templateData = docSnap.data();

                        // Grab the statistics.
                        charts.value = templateData.timeSeries.charts;

                        // Add any other additional subpage links
                        subpageLinks.value.set('Overview', [`/dashboard/${dataID}`]);

                        if (templateData.timeSeries) {
                            subpageLinks.value.set('Time Series', [null, 'inactive']);
                        }

                        if (templateData.events) {
                            subpageLinks.value.set('Events', [`/dashboard/${dataID}/events`]);
                        }

                    }
                } catch(error) {
                    console.log(error);
                }          
            };

            const checkActivity = () => {
                // Start a snapshot listener
                onSnapshot(doc(db, "data", dataID), (document) => {
                    const unitDetails = document.data();

                    if (unitDetails.friendlyName !== "") {
                        pageTitle.value = unitDetails.friendlyName;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold">Data ID: </span>
                            ${dataID}
                            <span style="font-weight: bold; margin-left: 20px;"> Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    
                    } else {
                        pageTitle.value = dataID;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold;">Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    }
                });
            };

            const applyCustomTimeRange = () => {
                if (timeSeriesRefs.value) {
                    timeSeriesRefs.value.forEach((timeSeriesRef) => {
                        if (timeSeriesRef && timeSeriesRef.setCustomTimeRange) {
                            timeSeriesRef.setCustomTimeRange(customTimeRange.value);
                        }
                    });
                }
                
            };

            const setLiveData = () => {
                customTimeRange.value = [];
                timeRange.value = [getPastTimestamp(24, 'local'), dateToString(new Date())];
                
                // Reset each TimeSeries component to live mode
                if (timeSeriesRefs.value) {
                    timeSeriesRefs.value.forEach((timeSeriesRef) => {
                        if (timeSeriesRef && timeSeriesRef.setCustomTimeRange) {
                            timeSeriesRef.initializeChart()
                        }
                    });
                }
            };

            const updateTimeRange = (newTimeStamp) => {
                if (newTimeStamp > timeRange.value[1]) {
                    timeRange.value[0] = getPastTimestamp(24, 'local');
                    timeRange.value[1] = newTimeStamp;
                }
            };

            const addTimeSeries = (template) => {
                charts.value.push(template);
            };

            const deleteTimeSeries = async (index) => {
                // Wait for the next tick to ensure all child components are stable
                await nextTick();
                
                // Create a new array without the deleted item
                const newCharts = charts.value.filter((_, i) => i !== index);
                
                // Clear the old array first
                charts.value = [];
                
                // Wait for the next tick to ensure the components are unmounted
                await nextTick();
                
                // Set the new array
                charts.value = newCharts;
            };

            const error = (message) => {
                console.log(message);
            };

            const closeModal = () => {
                modal.value = null;
                showVerticalLine.value = previousVerticalLineState.value;
            };

            const requestDataDownload = () => {
                // Reset values
                headers.value = ['Timestamp'];
                allData.value = {};
                dataRequests = timeSeriesRefs.value.length;
                dataResponses = 0;

                // Request the data from each TimeSeries
                if (timeSeriesRefs.value) {
                    timeSeriesRefs.value.forEach((timeSeriesRef) => {
                        if (timeSeriesRef && timeSeriesRef.emitData) {
                            timeSeriesRef.emitData();
                        }
                    });
                }
            };

            const handleDataRequest = (data) => {
                // Each TimeSeries may have multiple data sets.
                // Be sure to grab all of them.
                for (const dataSet of data) {
                    const { name, y, x } = dataSet;
                    headers.push(name);

                    for (let i = 0; i < x.length; i++) {
                        if (!allData[x[i]]) {
                            allData[x[i]] = {};
                        }
                        
                        allData[x[i]][name] = y[i];
                    }
                }

                dataResponses++;

                if (dataResponses === dataRequests) {
                    delete allData.value;
                    downloadCSV();
                }
            };

            const downloadCSV = () => {
                // Convert the data to a CSV
                const csvRows = [headers.join(',')];
                for (const [timestamp, values] of Object.entries(allData)) {
                    const row = [timestamp, ...headers.slice(1).map(header => values[header] ?? null)];
                    csvRows.push(row.join(','));
                }

                // Download the CSV
                const csv = csvRows.join('\n');
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', `${dataID}_data.csv`);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            };

            onMounted(() => {
                if (chartContainer.value) {
                    const handleMouseMove = (e) => {
                        if (isLineFrozen.value || !showVerticalLine.value) return;

                        const containerRect = chartContainer.value.getBoundingClientRect();
                        const leftLimit = leftBoundary;
                        const rightLimit = containerRect.width - rightBoundary;

                        // Calculate position relative to the container
                        const relativeX = e.clientX - containerRect.left;

                        updateLinePosition(relativeX, leftLimit, rightLimit, containerRect.height);
                    };

                    const handleMouseLeave = () => {
                        if (!isLineFrozen.value && verticalLine.value) {
                            verticalLine.value.style.visibility = 'hidden';
                            verticalLine.value.style.opacity = '0';
                        }
                    };

                    const handleClick = (e) => {
                        if (!showVerticalLine.value) return;

                        const containerRect = chartContainer.value.getBoundingClientRect();
                        const leftLimit = leftBoundary;
                        const rightLimit = containerRect.width - rightBoundary;
                        const relativeX = e.clientX - containerRect.left;

                        // Only toggle frozen state if click is within the valid range
                        if (relativeX >= leftLimit && relativeX <= rightLimit) {
                            isLineFrozen.value = !isLineFrozen.value;
                            
                            if (isLineFrozen.value) {
                                // Freeze the line at the current position
                                frozenPosition.value = relativeX;
                                updateLinePosition(relativeX, leftLimit, rightLimit, containerRect.height);
                            } else {
                                // Unfreeze the line
                                frozenPosition.value = null;
                            }
                        }
                    };

                    const updateLinePosition = (x, leftLimit, rightLimit, height) => {
                        if (!verticalLine.value) return;

                        if (x >= leftLimit && x <= rightLimit) {
                            verticalLine.value.style.visibility = 'visible';
                            verticalLine.value.style.opacity = '1';
                            verticalLine.value.style.left = `${x}px`;
                            verticalLine.value.style.height = `${height}px`;
                        } else {
                            verticalLine.value.style.visibility = 'hidden';
                            verticalLine.value.style.opacity = '0';
                        }
                    };

                    // Add event listeners to the chart container
                    chartContainer.value.addEventListener('mousemove', handleMouseMove);
                    chartContainer.value.addEventListener('mouseleave', handleMouseLeave);
                    chartContainer.value.addEventListener('click', handleClick);

                    // Clean up the event listeners when the component is unmounted
                    onUnmounted(() => {
                        if (chartContainer.value) {
                            chartContainer.value.removeEventListener('mousemove', handleMouseMove);
                            chartContainer.value.removeEventListener('mouseleave', handleMouseLeave);
                            chartContainer.value.removeEventListener('click', handleClick);
                        }
                    });
                }
            });

            return {
                pageTitle,
                pageSubtitle,
                subpageLinks,
                dataID,
                timeFormat,
                charts,
                timeRange,
                customTimeRange,
                liveUpdate,
                modal,
                uid,
                unitDetails,
                timeSeriesRefs,
                verticalLine,
                chartContainer,
                showVerticalLine,
                handleAddChart,
                handleModal,
                requestDataDownload,
                handleDataRequest,
                updateTimeRange,
                addTimeSeries,
                deleteTimeSeries,
                error,
                applyCustomTimeRange,
                setLiveData,
                closeModal
            }
        }
    };
</script>

<style scoped>
.vertical-line {
    position: fixed;
    top: 300px;
    bottom: 0px;
    width: 3px;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    z-index: 9999;
}

/* Add these styles for the toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .2s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .2s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Add transition for smooth visibility changes */
.absolute {
    transition: visibility 0.2s, opacity 0.2s;
}
</style>
