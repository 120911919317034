<template>
    <div class="flex bg-color-f rounded-lg m-[15px] text-left flex-1 flex-col">
        <h1 class=" text-center font-bold">{{ topic }}</h1>
        <p class="text-center">{{ date }}</p>
      <div class="mt-[5px] py-2 bg-color-e-darker pl-2 rounded-md flex-1">
        <p v-for="[detail, value] in statInfo" :key="detail"><span style="font-weight: bold">{{ detail }}: </span>{{ value }}</p>
      </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-setup-props-destructure */
    import { onMounted, ref } from 'vue';
    import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
    import { db } from '@/firebase';

    import { utcToLocal } from '@/tools/TimeFunctions';

    export default {
        name: 'StatsCard',
        props: {
            statDetails: Object,
            dataID: String,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },

        setup(props) {
            // Set up reference variables.
            const statInfo = ref(new Map());
            const date = ref('');

            // Set up all other variables.
            const topic = props.statDetails.topic;

            /**
             * Create the card based on the template given.
             */
            const createCard = onMounted(async() => {
                for (let subcollection in props.statDetails.specifications) {
                    grabData(subcollection);
                }
            });

            /**
             * Grab data from a subcollection in the database
             * @param   {String}    subcollection The subcollection the data is located
             */
            const grabData = (subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy('messageUTC', 'desc'), limit(1));
                onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data();
                        for (let obj of props.statDetails.specifications[subcollection]) {

                            if (obj.field) {
                                statInfo.value.set(obj.friendlyName || obj.field, Number(data[obj.field]).toFixed(obj.decimals || 0) + " " + (obj.unit || ''));
                            }
                            else if (obj.calculate) {
                                const { formula, denominator} = obj.calculate;
                                const variables = {};
                                denominator.forEach((field) => {
                                    variables[field] = data[field];
                                });
                                const calculateFn = new Function(...denominator, `return ${formula}`);
                                statInfo.value.set(obj.friendlyName, calculateFn(...Object.values(variables)).toFixed(obj.decimals || 0) + " " + (obj.unit || ''));
                            }
                        }

                        date.value = 'Last Update: ' + utcToLocal(data.messageUTC, props.timeFormat);
                    });
                });
            };

            return {
                topic,
                statInfo,
                date,
                createCard
            }
        },
    };
</script>
<style>
    .stats-card {
        background-color: rgb(204, 204, 204);
        border-radius: 5px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 15px;
        text-align: left;
        flex: 1;
    }

    .stats-card p {
        margin: 0;
    }

    .data-container {
        margin-top: 20px;
    }

</style>