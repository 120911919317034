<template>
    <DefaultLayout
    pageTitle="Home"
    ></DefaultLayout>
</template>
  
<script>
  import DefaultLayout from '@/layouts/Layout.vue'
  
  export default {
    name: 'HomeMain',
    components: {
      DefaultLayout
    }
  }
</script>
  
<style>
</style>