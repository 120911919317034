<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center" @click.self="close">
    <div class=" bg-color-f p-[20px] rounded-lg max-w-[400px] max-h-[calc(100vh-50px)] overflow-y-auto">
      <h2 class=" font-extrabold text-xl underline">New Event Table</h2>
      <br>
      <form @submit.prevent="handleSubmit">

        <label class="font-bold">
          Event Name:
          <br>
          <input v-model="title" type="text" required />
        </label>

        <br><br>

        <label class="font-bold">
          Source:
          <br>
          <select v-model="source" style="width: 100%;" @change="getFieldOptions($event)" required>
            <option v-for="subcollection in subcollections" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
          </select>
        </label>

        <br><br>

        <!-- Event Operation -->
        <div class="flex flex-row justify-between">
          <label class="font-bold w-[175px] mr-3">
            value: <span v-if="!fields.length" class="font-normal text-red-600">(select source)</span>
            <br>
            <select v-model="field" style="width: 100%;" required>
              <option v-for="field in fields" :key="field" :value="field">{{ field }}</option>
            </select>
          </label>

          <label class="font-bold w-[75px] mr-3">
            Operator:
            <br>
            <select v-model="operator" style="width: 100%;" required>
              <option value=">">&gt;</option>
              <option value=">=">&gt;=</option>
              <option value="<">&lt;</option>
              <option value=">=">&lt;=</option>
              <option value="==">=</option>
              <option value="!=">&ne;</option>
            </select>
          </label>

          <label class="font-bold w-[75px]">
            Constant:
            <br>
            <input v-model="constant" type="text" required />
          </label>

        </div>
        <!-- <br>
        
        <label class="font-bold">
          Transform Value (Optional)
          <input v-model="transform" type="text">
        </label>
        <br><br>
       
        
        <h3 class="font-bold">Default Columns</h3>

        <div class="flex justify-between">
          <label>
            <input v-model="startTime" type="checkbox" style="width: 17px;"/>
            Start Time
          </label>

          <label>
            <input v-model="endTime" type="checkbox" style="width: 17px;"/>
            End Time
          </label>

          <label>
            <input v-model="elapsedTime" type="checkbox" style="width: 17px;"/>
            Elapsed Time
          </label>
        </div>

        <br>
        <div v-for="(column, index) in additionalColumns" :key="index" class="border-t border-black pt-3">
          <label>
            <div class="flex items-center">
              Custom Column Name
              <TrashIcon @click="removeColumn(index)" class="ml-3 h-4 w-4 hover:cursor-pointer"/>
            </div>
            <input type="text" :value="additionalColumns[index].name" @input="updateColumn(index, 'name', $event)"/>
          </label>
          <br><br>
          <label class="font-bold w-[175px] mr-3">
            value: <span v-if="!fields.length" class="font-normal text-red-600">(select source)</span>
            <br>
            <select v-model="field" style="width: 100%;" required>
              <option v-for="field in fields" :key="field" :value="field">{{ field }}</option>
            </select>
          </label>
          <br><br>
        </div>
        <button type="button" @click="addColumn" class=" text-color-a font-bold">+ Add Custom</button> -->
        <br><br>
        <div class="flex justify-between">
          <button @click="close" class="default-button">Close</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          <button v-else type="submit" :disabled="loading" class="default-button">Create</button>
        </div>
      </form>
    </div>
    <div id="preview">

    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
// import { TrashIcon } from '@heroicons/vue/24/solid';

import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

export default {
  name: 'CreateEvent',
  components: {
    LoadingAnimation1,
    // TrashIcon
  },
  props: {
    dataID: String,
    uid: String,
  },
  setup(props, { emit }) {
    const title = ref(null);
    const source = ref(null);
    const startTime = ref(true);
    const endTime = ref(true);
    const elapsedTime = ref(true);
    const field = ref(null);
    const operator = ref(null);
    const constant = ref(null);
    const additionalColumns = ref([]);
    const loading = ref(false);
    const subcollections = ref([]);
    const fields = ref([]);

    onMounted(async() => {
      const body = {
        uid: props.uid,
        dataID: [props.dataID]
      }

      try {
        const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/device_requests', {
          method: 'POST',
          headers: {
            'content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        const responseData = await response.json();
        subcollections.value = responseData.details[props.dataID].subcollections;

      } catch (error) {
        console.log(error);
      }
    });

    const handleSubmit = () => {
      loading.value = true;
      const formData = {
        startTime: startTime.value,
        endTime: endTime.value,
        elapsedTime: elapsedTime.value,
        columns: additionalColumns.value,
        title: title.value,
        source: source.value,
        field: field.value,
        operator: operator.value,
        constant: constant.value,
        type: 'event',
      };
      emit('submitForm', formData);
    };

    const getFieldOptions = async(event) => {
      const body = {
        uid: props.uid,
        dataID: props.dataID,
        subcollection: event.target.value
      }

      try {
        const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/device_requests/subcollection_fields', {
          method: 'POST',
          headers: {
            'content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        const responseData = await response.json();
        fields.value = responseData.details;

      } catch (error) {
        console.log(error);
      }
    };

    const addColumn = () => {
      additionalColumns.value.push({
        name: null,
        field: null,
      });
    };

    const updateColumn = (index, inputType, event) => {
      additionalColumns.value[index][inputType] = event.target.value;
    };

    const removeColumn = (index) => {
      additionalColumns.value = additionalColumns.value.filter((_, i) => i !== index);
    };

    const close = () => {
      emit('close');
    };

    return {
      title,
      source,
      startTime, 
      endTime,
      elapsedTime,
      field,
      operator,
      constant,
      additionalColumns,
      loading,
      subcollections,
      fields,
      handleSubmit,
      getFieldOptions,
      addColumn,
      updateColumn,
      removeColumn,
      close,
    };
  }
};
</script>

<style scoped>

input {
  width: 100%;
}
</style>