<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <h2 class=" text-2xl mb-3">New User</h2>
      <form @submit.prevent="handleSubmit">
        <label>
          First Name:
          <br>
          <input v-model="firstName" type="text" required />
        </label>
        <br><br>
        <label>
          Last Name:
          <br>
          <input v-model="lastName" type="text" required/>
        </label>
        <br><br>
        <!-- <label>
          Company:
          <br>
          <input v-model="company" type="text" required/>
        </label>
        <br><br> -->
        <label>
          Email:
          <br>
          <input v-model="email" type="text" required/>
        </label>
        <br><br>
        <label>
          role:
          <br>
          <select v-model="role" required>
            <option value="viewer">viewer</option>
            <!-- <option value="editor">editor</option> -->
            <option value="admin">admin</option>
          </select>
        </label>
        <br><br>
        <!-- <label>
          Permissions:
          <br>
          <input v-model="permissions" type="text"/>
        </label>
        <br><br> -->
        <!-- <label>
          Units:
          <br>
          <input v-model="Units" type="text"/>
        </label>
        <br><br> -->
        <label>
          Profile Picture:
          <br>
          <input type="file" accept="image/*" @change="setFile('profilePic', $event)" />
        </label>
        <br><br>
        <div class="flex justify-between">
          <button @click="close" class="default-button">Close</button>
          <button type="submit" :disabled="loading" class="default-button">Create</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

export default {
  name: 'CreateUser',
  components: {
    LoadingAnimation1
  },
  setup(_, { emit }) {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const entity = ref('');
    const role = ref('viewer');
    const company = ref('');
    const timeFormat = ref('24Hour');
    // const permissions = ref({});
    // const units = ref([]);
    const profilePic = ref(null);
    const loading = ref(false);

    const handleSubmit = () => {
      loading.value = true;
      const formData = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        entity: entity.value,
        role: role.value,
        company: company.value,
        // permissions: permissions.value,
        // units: units.value,
        profilePic: profilePic.value
      };
      emit('submitForm', formData);
    };

    const setFile = (type, event) => {
      const file = event.target.files[0];
      if (file) {
        if (type === 'profilePic') {
          profilePic.value = file
        }
      }
    };

    const close = () => {
      emit('close');
    };

    return {
      firstName, 
      lastName,
      email,
      entity,
      role,
      company,
      timeFormat,
      // permissions,
      // units,
      profilePic,
      handleSubmit,
      setFile,
      close,
      loading
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
}

input {
  width: 100%;
}
</style>