<template>
    <DefaultLayout
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    :subpageLinks="subpageLinks"
    >
  
    <!-- Page content goes here -->
  
    </DefaultLayout>
  </template>
    
  <script>
    import { useStore } from 'vuex';
    import { ref, onMounted } from 'vue';
    import { doc, getDoc, onSnapshot } from "firebase/firestore";
    import { db } from '@/firebase';
    import { router } from '@/router';
    
    import { timeSimplified, utcToLocal } from '@/tools/TimeFunctions';
    import DefaultLayout from '@/layouts/Layout.vue';
    
    export default {
        name: 'MainTemplate',
        components: {
            DefaultLayout
            // add other components here
        },
        props: {
            // provided values
        },
        setup() {
            const pageTitle = ref('');
            const pageSubtitle = ref('');
            const subpageLinks = ref(new Map());
            const unitDetails = ref({});

            // Set up all other variables
            const store = useStore();
            const timeFormat = store.state.user.timeFormat;
            const dataID = router.currentRoute.value.params.dataID;

            onMounted(async () => {
                await getUnitDetails();
                checkActivity();
                getTemplate();
            });

            /**
             * Grabs the unit details and sets up the page title
             */
            const getUnitDetails = async() => {
                
                // Attempt the data document using the data ID
                try {
                    const docRef = doc(db, "data", dataID);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        unitDetails.value = docSnap.data();
                    }
                } catch(error) {
                    console.log(error)
                    pageTitle.value = dataID;
                    pageSubtitle.value = "Faild to retrieve data";
                }
            };

            const checkActivity = () => {
                // Start a snapshot listener
                onSnapshot(doc(db, "data", dataID), (document) => {
                    const unitDetails = document.data();

                    if (unitDetails.friendlyName !== "") {
                        pageTitle.value = unitDetails.friendlyName;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold">Data ID: </span>
                            ${dataID}
                            <span style="font-weight: bold; margin-left: 20px;"> Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    
                    } else {
                        pageTitle.value = dataID;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold;">Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    }
                });
            };

            /**
             * Grabs the template for the unit and adds additional subpage links
             */
            const getTemplate = async() => {

                // The template is not stored so grab it from the database.
                try {
                    const docRef = doc(db, "templates", unitDetails.value.defaultTemplate);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Grab the document data and store it
                        const templateData = docSnap.data();

                        // Add any other additional subpage links
                        subpageLinks.value.set('Overview', [`/dashboard/${dataID}`]);

                        if (templateData.timeSeries) {
                            subpageLinks.value.set('Time Series', [`/dashboard/${dataID}/events`]);
                        }

                        if (templateData.events) {
                            subpageLinks.value.set('Events', [`/dashboard/${dataID}/events`]);
                        }

                    }
                } catch(error) {
                    console.log(error);
                }          
            };

            return {
                pageTitle,
                pageSubtitle,
                subpageLinks
            };
        }
    }
  </script>
    
  <style>
  </style>