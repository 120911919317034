<template>
    <DefaultLayout
    pageTitle="Settings"
    >
      <div class="flex h-full justify-between">
        <div class="flex-0 flex-shrink-0 bg-color-e w-[130px]">
          <div class="px-[4px]">
            <!-- <p>General</p> -->
            <p @click="settingPage = 'myCompany'" class="font-large text-black bg-color-c-lighter w-auto p-[10px] my-[5px] rounded-md hover:cursor-pointer hover:bg-color-c">My Company</p>
            <p @click="settingPage = 'subEntities'" class="font-large text-black bg-color-c-lighter w-auto p-[10px] my-[5px] rounded-md hover:cursor-pointer hover:bg-color-c">Sub-Entities</p>
            <!-- <p>Security</p> -->
            <!-- <p>Users</p> -->
          </div>
        </div>

        <div v-if="settingPage === 'myCompany'" class="flex flex-grow flex-col pl-[50px] bg-color-f">
          <SettingsCompany></SettingsCompany>
        </div>
        <div v-else-if="settingPage === 'subEntities'" class="flex flex-grow flex-col pl-[50px] bg-color-f">
          <SettingsEntities></SettingsEntities>
        </div>
      </div>
    </DefaultLayout>
</template>
  
<script>
  import { ref } from 'vue';
  import DefaultLayout from '@/layouts/Layout.vue';
  import SettingsCompany from './SettingsCompany.vue';
  import SettingsEntities from '@/pages/SettingsEntities.vue';
  
  export default {
    name: 'PortalSettings',
    components: {
      DefaultLayout,
      SettingsCompany,
      SettingsEntities
    },
    setup() {
      const settingPage = ref('myCompany');

      return {
        settingPage,
      }
    }
  }
</script>
  
<style>
  .settings-body {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  .settings-sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #174a68;
    width: 130px;
  }

  .settings-options {
    padding-right: 4px;
    padding-left: 4px;
  }

  .settings-options p {
    font-size: large;
    color: white;
    background-color: #226f9b;
    width: auto;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 10px;
  }

  .settings-options p:hover {
    background-color: #174a68;
  }

  .settings-details {
    flex-grow: 1;
    padding-left: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
</style>