<template>
    <div class="bg-white rounded-md p-3">
        <h1 style="margin-top: 0;">{{ title }}</h1>
            <div class="overflow-x-auto max-w-full">
            <table class="w-full bg-white border-collapse whitespace-nowrap">
                <thead>
                    <tr>
                        <th v-for="column in tableData.keys()" :key="column" class=" bg-sky-600 p-2 text-left min-w-32">{{ column }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="index in 10" :key="index" :class="{'bg-slate-200': index % 2 !== 0}">
                        <td v-for="value in tableData.values()" :key="value" class="p-2 text-left min-w-32">{{ value[index-1] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-setup-props-destructure */
    import { onMounted, ref } from 'vue';
    import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
    import { db } from '@/firebase';

    import { utcToLocal } from '@/tools/TimeFunctions';

    export default {
        name: 'GenericTable',
        props: {
            dataID: String,
            template: Object,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },
        setup(props) {
            const title = ref(props.template.title);
            const tableData = ref(new Map());

            /**
             * Initates the creation of the table
             */
            const createTable = onMounted(() => {
                for (let subcollection in props.template.specifications) {
                        grabData(subcollection);
                    }
            });

            /**
             * Grab data from a subcollection in the database
             * @param   {String}    subcollection The subcollection the data is located
             */
            const grabData = (subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy('messageUTC', 'desc'), limit(10));
                onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data();
                        for (let obj of props.template.specifications[subcollection]) {
                            let column = obj.friendlyName || obj.field.charAt(0).toUpperCase() + obj.field.slice(1);
                            let value = data[obj.field];
                            if (column === 'Time Stamp') {
                                value = utcToLocal(value, props.timeFormat);
                            }
                            if (tableData.value.has(column)) {
                                let columnValues = tableData.value.get(column)
                                columnValues.push(value);

                                if (columnValues.length > 10) {
                                    columnValues = columnValues.slice(columnValues.length - 10)
                                }

                                tableData.value.set(column, columnValues);
                            } else {
                                tableData.value.set(column, [value]);
                            }
                        }
                    });
                });
            };

            return {
                title,
                tableData,
                createTable
            };
        },
    };
</script>
