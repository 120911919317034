<template>
    <div class="relative">
        <input
            type="text"
            placeholder="YYYY-MM-DD HH:mm:ss ~ YYYY-MM-DD HH:mm:ss"
            v-model="inputValue"
            @click.stop="openCalander = true"
            class="border p-2 rounded w-full"
            :class="[validValue ? 'bg-white' : 'bg-red-300']"
        />
        <VueTailwindDatepicker
            v-if="openCalander"
            :formatter="formatter" 
            :shortcuts="customShortcuts"
            :theme="customTheme"
            v-model="dateValue"
            no-input
            class="absolute mt-1 z-10"
        ></VueTailwindDatepicker>
    </div>
</template>

<script>
    // import colors from 'tailwindcss/colors';
    import { ref, watch, onMounted, onUnmounted } from 'vue'; 
    import VueTailwindDatepicker from 'vue-tailwind-datepicker';

    export default {
        name: 'DatePicker',
        components: {
            VueTailwindDatepicker
        },
        props: {
            modelValue: {
                type: Array,
                default: () => []
            }
        },
        setup(props, { emit }) {
            const inputValue = ref('');
            const dateValue = ref(props.modelValue);
            const validValue = ref(true);
            const openCalander = ref(false);
            const formatter = ref({
                date: "YYYY-MM-DD HH:mm:ss",
                month: "MMM"
            });
            const customShortcuts = () => [
                {
                    label: "Today",
                    atClick: () => {
                        const startOfDay = new Date().setHours(0, 0, 0, 0);
                        const now = new Date().setHours(23, 59, 59, 999);
                        return [startOfDay, now];
                    },
                },
                {
                    label: "Yesterday",
                    atClick: () => {
                        const now = new Date();
                        const yesterday = now.setDate(now.getDate() -1);

                        const startOfDay = new Date(yesterday).setHours(0, 0, 0, 0);
                        const endOfDay = new Date(yesterday).setHours(23, 59, 59, 999);

                        return [startOfDay, endOfDay];
                    },
                },
                {
                    label: "Last 7 Days",
                    atClick: () => {
                        const now = new Date();
                        const startDate = new Date(now.setDate(now.getDate() -7)).setHours(0, 0, 0, 0);
                        const endDate = new Date().setHours(23, 59, 59, 999);
                        return [startDate, endDate];
                    },
                },
                {
                    label: "Last 15 Days",
                    atClick: () => {
                        const now = new Date();
                        const startDate = new Date(now.setDate(now.getDate() -15)).setHours(0, 0, 0, 0);
                        const endDate = new Date().setHours(23, 59, 59, 999);
                        return [startDate, endDate];
                    },
                },
                {
                    label: "This Month",
                    atClick: () => {
                        const now = new Date();
                        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
                        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
                        return [startOfMonth, endOfMonth];
                    },
                },
                {
                    label: "Last Month",
                    atClick: () => {
                        const now = new Date();
                        const startOfMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0, 0);
                        const endOfMonth = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
                        return [startOfMonth, endOfMonth];
                    },
                },
            ];
            const customTheme = {
                primary: '#4CAF50',    // Green for primary color
                secondary: '#FF5722',  // Orange for secondary color
                backgroundColor: '#FFFFFF', // White background
                textColor: '#000000',  // Black text
                borderColor: '#E0E0E0' // Light gray borders
            };

            onMounted(() => {
                document.addEventListener('click', handleClickOutside);
            });

            // When dates are selected using the calendar, set the inputValue equal to it
            // to display the newValue in the text field. 
            watch(dateValue, (newValue) => {
                inputValue.value = newValue.join(',').replace(/,/g, ' ~ ');
            });

            // When changes are made to the text field, emit the newValue if
            // it's in the correct format.
            const dateFormat = /^(20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]) ~ (20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
            watch(inputValue, (newValue) => {

                // Confirm the textbox value follows the proper format.
                if (dateFormat.test(newValue)) {

                    // Check to see if the first date is less than the second date.
                    const [dateTime1, dateTime2] = newValue.split(' ~ ');
                    const date1 = new Date(dateTime1);
                    const date2 = new Date(dateTime2);
                    if (date1 < date2) {
                        emit('update:modelValue', newValue.split(' ~ '));
                        validValue.value = true;
                    }
                    else {
                        validValue.value = false;
                    }
                    
                }
                else if (newValue === '') {
                    validValue.value = true;
                }
                else {
                    validValue.value = false;
                }
            });

            const handleClickOutside = (event) => {
                if (!event.target.closest('.relative')) {
                    openCalander.value = false;
                }
            };

            onUnmounted(() => {
                document.removeEventListener('click', handleClickOutside)
            });

            return {
                inputValue,
                dateValue,
                validValue,
                openCalander,
                formatter,
                customTheme,
                customShortcuts,
            };
        },
    };
</script>