<template>
    <div class="g bg-white rounded-md p-3">
        <h1 style="margin-top: 0;">Event Log</h1>
        <table class="w-full bg-white border-collapse">
            <thead>
                <tr>
                    <th v-for="column in tableData.keys()" :key="column" class="bg-sky-600 p-2 text-left">{{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="index in 5" :key="index" :class="{'bg-slate-200': index % 2 !== 0}" class="h-9">
                    <td v-for="value in tableData.values()" :key="value" class="p-2 text-left">{{ value[index-1] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
/* eslint-disable vue/no-setup-props-destructure */
    import { onMounted, ref } from 'vue';
    import { doc, onSnapshot } from 'firebase/firestore';
    import { db } from '@/firebase';

    import { utcToLocal } from '@/tools/TimeFunctions';

    export default {
        name: 'EventLog',
        props: {
            dataID: String,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },
        setup(props) {
            const tableData = ref(new Map([
                ['Time Stamp', []],
                ['Event', []]
            ]));

            /**
             * Initates the creation of the table
             */
            const createTable = onMounted(() => {
                grabData();
            });

            /**
             * Grab data from the data collection
             */
            const grabData = () => {
                onSnapshot(doc(db, "data", props.dataID), (document) => {
                    const docData = document.data();
                    let timestamps = tableData.value.get('Time Stamp');
                    let events = tableData.value.get('Event')

                    timestamps.unshift(utcToLocal(docData.lastActivityUTC, props.timeFormat));
                    events.unshift(docData.lastMessage);

                    if (timestamps.length > 5) {
                        timestamps.pop();
                        events.pop();
                    }

                    tableData.value.set('Time Stamp', timestamps);
                    tableData.value.set('Event', events);
                });
            };

            return {
                tableData,
                createTable
            };
        },
    };
</script>

<!-- <style>
    table {
    width: 100%;
    background-color: white;
    border-collapse: collapse;
    }

    th {
        background-color: rgb(48, 145, 255);
    }

    th, td {
    /* border: 1px solid black; */
    padding: 8px;
    text-align: left;
    }

    tr {
        height: 35px;
    }

    .event-log {
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .alternate-color {
        background-color: rgb(211, 211, 211);
    }
</style> -->