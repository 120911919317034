<template>
    <div class="bg-color-f rounded-lg p-5 h-fit mb-5 mr-3 w-full relative">
        <div class="absolute top-2 right-2 z-10 flex">
            <PencilIcon @click="updateChart" class="w-5 h-5 mr-5 hover:cursor-pointer"></PencilIcon>
            <LoadingAnimation1 v-if="loading === 'deleting'"></LoadingAnimation1>
            <TrashIcon v-else @click="deleteChart" class="w-5 h-5 hover:cursor-pointer"></TrashIcon>
        </div>
        <VuePlotly
            :key="key"
            :data="data"
            :layout="layout"
            :config="config"
            @mouseover="onMouseOver"
        ></VuePlotly>
    </div>
    <UpdateTemplate v-if="modal === 'edit'"
        :dataID="dataID"
        :index="index"
        :uid="uid"
        :page="page"
        :templateID="templateID"
        :widget="'charts'"
        :topic="templateData.title"
        :subcollection="subcollection"
        :values="modalData"
        @close="closeModal"
        @widget="editChart"
    ></UpdateTemplate>
</template>
  
<script>
    import { useStore } from 'vuex';
    import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
    import { VuePlotly } from 'vue3-plotly';
    import { getPastTimestamp, utcToLocal, dateToString } from '@/tools/TimeFunctions';
    import { collection, query, orderBy, limit, getDocs} from 'firebase/firestore';
    import { db } from '@/firebase';
    import { TrashIcon, PencilIcon } from '@heroicons/vue/24/solid';

    import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
    import UpdateTemplate from '../modals/updateTemplate.vue';
  
    export default {
        name: 'TimeSeries',
        components: {
            VuePlotly,
            TrashIcon,
            PencilIcon,
            LoadingAnimation1,
            UpdateTemplate,
        },
        props: {
            page: String,
            index: Number,
            templateID: String,
            template: Object,
            dataID: String,
            live: Boolean,
            timeRange: {
                type: Array,
                default: () => [getPastTimestamp(24, 'local'), dateToString(new Date())]
            }
        },
        emits: ['newMessageUTC', 'error', 'delete', 'modal', 'dataRequested', 'newTimeStamp'],
        setup(props, { emit }) {
            const key = ref(0);
            const subcollection = ref(Object.keys(props.template.specifications)[0]);
            const data = ref([]);
            const config = ref({displaylogo: false})
            const loading = ref(null);
            const modal = ref(null);
            const modalData = ref([]);
            const templateData = ref(props.template);
            const layout = ref(null);
            const snapshotWorkers = ref({});
            const unsubscribes = ref({});

            const store = useStore();
            const uid = ref(store.state.user.uid);

            const initializeChart = onMounted(() => {
                setLayout();
                data.value = [];
                grabData();
            });

            // Don't forget to clean up workers when component is unmounted
            onBeforeUnmount(() => {
                Object.values(snapshotWorkers.value).forEach(worker => {
                    worker.terminate();
                });
                Object.values(unsubscribes.value).forEach(unsubscribe => {
                    if (unsubscribe) unsubscribe();
                });
            });

            const setLayout = () => {
                layout.value = {
                    // plot_bgcolor: '#eeeeee',
        
                    showlegend: true,
                    legend: {
                        x: 0,
                        y: -0.8,
                        orientation: 'h',
                    },
                    title: {
                        text: templateData.value.title,
                        font: {
                            size: 25,
                            weight: 'bold',
                        },
                        xref: 'paper',
                        x: 0,
                        xanchor: 'left',
                        y: 1,
                        yanchor: 'top',
                        pad: { t: 10, b:10},
                    },
                    annotations: [
                        {
                            text: 'Broken',
                            xref: 'paper',
                            yref: 'paper',
                            x: 0.0,
                            y: -1.0,
                            showarrow: false,
                        },
                    ],
                    xaxis: {
                        autorange: false,
                        range: props.timeRange,
                        mirror: true,
                        ticks: 'outside',
                        showline: true,
                        type: 'date',
                        rangeselector: {
                            // bgcolor: 'blue',
                            // font: { color: 'white'},
                            buttons: [
                                {
                                    count: 24,
                                    label: '24hr',
                                    step: 'hour',
                                    stepmode: 'backward',
                                },
                                {
                                    count: 12,
                                    label: '12hr',
                                    step: 'hour',
                                    stepmode: 'backward'
                                },
                                {
                                    count: 30,
                                    label: '30min',
                                    step: 'minute',
                                    stepmode: 'backward'
                                },
                                {
                                    count: 10,
                                    label: '10min',
                                    step: 'minute',
                                    stepmode: 'backward'
                                },
                            ]
                        },
                        rangeslider: {
                            visible: true,
                            range: props.timeRange,
                            borderwidth: 1,
                            bordercolor: 'black'
                        },
                    },
                    yaxis: {
                        autorange: true,
                        mirror: true,
                        ticks: 'outside',
                        showline: true,
                        type: 'linear'
                    },
                }
            };

            const initializeWorker = (subcollection) => {
                snapshotWorkers.value[subcollection] = new Worker(
                    new URL('@/components/WebWorkers/snapshotWorker.js', import.meta.url),
                    { type: 'module' }
                );
                snapshotWorkers.value[subcollection].onmessage = (event) => {
                    const { type, payload } = event.data;
                    if (type === 'DATA_ADDED') {
                        if (Array.isArray(payload.data)) {
                            // Handle historical data from TIME_RANGE query
                            payload.data.forEach(doc => {
                                handleLiveData(payload.subcollection, doc, true);
                            });
                        } else {
                            // Handle live data updates
                            for (let doc of payload.data?.added || []) {
                                handleLiveData(payload.subcollection, doc, payload.firstRun);
                            }
                        }
                    } else if (type === 'ERROR') {
                        console.error(`Error in worker for ${payload.subcollection}:`, payload.error);
                    }
                };
            };

            const grabData = () => {
                for (let subcollection in templateData.value.specifications) {
                    initializeWorker(subcollection);
                    
                    for (let obj of templateData.value.specifications[subcollection]) {
                        data.value.push({
                            type: "scatter",
                            mode: "lines+markers",
                            name: obj.friendlyName,
                            x: [],
                            y: [],
                            line: {
                                color: obj.color
                            },
                            marker: {
                                size: 6,
                                symbol: 'circle',
                                maxdisplayed: 50,
                            },
                            hovertemplate: `%{x} = %{y} ${obj.unit || ''}`,
                        });
                    }
                    getLastUpdate(subcollection);
                    startSnapshotWorker(subcollection);
                }
            };

            const getLastUpdate = async(subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy("messageUTC", "desc"), limit(1));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    layout.value.annotations[0].text = `Last Update: ${utcToLocal(doc.data().messageUTC, '12hour')}`;
                });
                key.value += 1;
            };

            const startSnapshotWorker = (subcollection, type = 'LIVE_DATA', timeRange = props.timeRange[0]) => {
                let payload;
                if (type === 'LIVE_DATA') {
                    payload = {
                        dataID: props.dataID,
                        subcollection: subcollection,
                        timeRange: timeRange
                    }
                } else if (type === 'TIME_RANGE') {
                    payload = {
                        dataID: props.dataID,
                        subcollection: subcollection,
                        startTime: timeRange[0],
                        endTime: timeRange[1]
                    }
                }
                snapshotWorkers.value[subcollection].postMessage({
                    type: type,
                    payload: payload
                });
            };

            const handleLiveData = (subcollection, docData, firstRun) => {
                // Grab data for each object in the subcollection query.
                for (let obj of templateData.value.specifications[subcollection]) {
                    
                    // Convert the timestamp to local time and display it.
                    const newTimeStamp = utcToLocal(docData.messageUTC, '24hour');
                    layout.value.annotations[0].text = `Last Update: ${utcToLocal(docData.messageUTC, '12hour')}`;

                    // Find the data object in the data array.
                    const foundData = data.value.find(item => item.name === obj.friendlyName);
                    if (firstRun) {
                        foundData.x.push(newTimeStamp);
                        if (obj.calculation) {
                            const variables = {};
                            obj.variables.forEach((field) => {
                                variables[field] = docData[field];
                            });
                            const calculateFn = new Function(...obj.variables, `return ${obj.formula}`);
                            foundData.y.push(calculateFn(...Object.values(variables)).toFixed(2));
                        }
                        else {
                            foundData.y.push(docData[obj.field]);
                        }
                    } else{

                        // Find the index to insert the new data.
                        const insertIndex = foundData.x.findIndex(timeStamp => new Date(timeStamp) > new Date(newTimeStamp));
                        
                        // Emit the new message time stamp.
                        emit('newTimeStamp', newTimeStamp);

                        // If the data object asks for a calculation, calculate the y value.
                        let yValue;
                        if (obj.calculation) {
                            const variables = {};
                            obj.variables.forEach((field) => {
                                variables[field] = docData[field];
                            });
                            const calculateFn = new Function(...obj.variables, `return ${obj.formula}`);
                            yValue = calculateFn(...Object.values(variables)).toFixed(2);
                        }

                        // Otherwise, just use the field value.
                        else {
                            yValue = docData[obj.field];
                        }

                        if (insertIndex === -1) {
                            // If the revieved data is the most current, append to the end
                            foundData.x.push(newTimeStamp);
                            foundData.y.push(yValue);
                        } else {
                            // Insert at the correct position
                            foundData.x.splice(insertIndex, 0, newTimeStamp);
                            foundData.y.splice(insertIndex, 0, yValue);
                        }

                        // If the data array is too long, remove the oldest data.
                        if (key.value >= 1 && foundData.x[0] < getPastTimestamp(24, 'local')) {
                            foundData.x.shift();
                            foundData.y.shift();
                        }
                    }
                }
                key.value ++;
            };

            watch(() => props.timeRange, (newTimeRange) => {
                layout.value.xaxis.range = newTimeRange;
                layout.value.xaxis.rangeslider.range = newTimeRange;
                key.value++;
            }, { deep: true });

            const updateChart = () => {
                emit('modal', 'edit');
                let index = 0;
                console.log(templateData.value.specifications);
                for (let key of Object.keys(templateData.value.specifications)) {
                    for (let obj of templateData.value.specifications[key]) {
                        modalData.value[index] = { ...obj, source: key };
                        index += 1;
                    }
                }
                modal.value = 'edit';
            };

            const deleteChart = async() => {
                loading.value = 'deleting';

                let responseData;
                try {
                    const body = {
                        uid: uid.value,
                        templateID: props.templateID,
                        dashboard: props.page,
                        widgetType: 'charts',
                        widgetID: props.index,
                    };

                    // Make the DELETE API call
                    const response = await fetch(`https://us-central1-mesh-db-stg.cloudfunctions.net/templates`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;

                if (responseData.code === 200) {
                    emit('delete');
                } else {
                    console.log(responseData);
                    emit('error', responseData.message);
                }
            };

            const closeModal = () => {
                modal.value = null;
                emit('modal', null);
            };

            const editChart = async(template) => {
                templateData.value = template;
                setLayout();
                data.value = [];
                grabData();
            };

            const emitData = () => {
                emit('dataRequested', data.value);
            };

            const setCustomTimeRange = (customRange) => {
                if (customRange && customRange.length === 2) {
                    // Update the layout with new time range
                    layout.value.xaxis.range = customRange;
                    layout.value.xaxis.rangeslider.range = customRange;
                    key.value++;

                    // Restart snapshot workers with new time range
                    for (let subcollection in templateData.value.specifications) {
                        // Clear existing data
                        data.value.forEach(series => {
                            series.x = [];
                            series.y = [];
                        });
                        
                        // Restart worker with new time range
                        startSnapshotWorker(subcollection, 'TIME_RANGE', customRange);
                    }
                }
            };

            const onMouseOver = (event) => {
                // console.log(event.target.classList);
                if (event.target.classList.contains('nsewdrag')) {
                    console.log("Mouse over plot");
                }
            };
        
            return {
                uid,
                loading,
                modal,
                modalData,
                templateData,
                subcollection,
                data,
                layout,
                key,
                config,
                initializeChart,
                updateChart,
                deleteChart,
                editChart,
                onMouseOver,
                closeModal,
                emitData,
                setCustomTimeRange,
            };
        },
    };
</script>

<style>
</style>