<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="close">
    <div class=" bg-color-f p-[20px] rounded-lg max-w-[400px] max-h-[calc(100vh-50px)] overflow-y-auto">
      <h2 class=" font-extrabold text-xl underline">Widget Settings</h2>
      <br>
      <form @submit.prevent="handleSubmit">

        <!-- Widget Type -->
        <label class="font-bold w-[75px] mr-3">
            Widget Type:
            <br>
            <select v-model="type" style="width: 100%;" required :disabled="widget">
              <option value="guages">Guage</option>
              <option value="table">Table</option>
              <option value="charts">Chart</option>
              <option value="maps">Map</option>
              <option value="infoCards">List</option>
            </select>
        </label>
        
        <br><br>

        <!-- Title -->
        <label class="font-bold">
          Title:
          <br>
          <input v-model="title" type="text" required />
        </label>

        <br><br>

<!-- Inputs for Charts -->
        <div v-if="type === 'charts'">
          <div v-for="(value, index) in additionalValues" :key="index" class="border-t-4 border-black pt-3 mb-3">
            <div v-if="additionalValues.length > 1" @click="removeValue(index)" class="flex gap-2 items-center hover:cursor-pointer text-red-500">
              <TrashIcon class="h-4 w-4"/>
              <p class="font-bold">Remove</p>
              <br><br>
            </div>

            <!-- Raw Value or Calculation -->
            <div>
                <span class="mr-3">Raw Value</span>
                <label class="relative inline-block w-[60px] h-[34px]">
                    <input class="opacity-0 w-0 h-0 peer" type="checkbox" v-model="additionalValues[index].calculation">
                    <span class="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-color-a-lighter duration-200 before:absolute before:content-[''] before:h-[26px] before:w-[26px] before:left-[4px] before:bottom-[4px] before:bg-white before:duration-200 rounded-[34px] before:rounded-[50%] peer-checked:before:transform peer-checked:bg-green-500 peer-checked:before:translate-x-[26px]"></span>
                </label>
                <span class="ml-3">Calculation</span>
            </div>

            <!-- Raw Value options -->
            <div v-if="!additionalValues[index].calculation">
              <!-- Source -->
              <label class="font-bold">
                  Source:
                <select v-model="additionalValues[index].source" style="width: 100%;" @change="getFieldOptions(index, $event)" required>
                  <option v-for="subcollection in Object.keys(subcollections)" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
                </select>
              </label>

              <br><br>

              <!-- field -->
              <label class="font-bold w-[175px] mr-3">
                Value: <span v-if="!subcollections[additionalValues[index].source]?.fields" class="font-normal text-red-600">(select source)</span>
                <select :value="additionalValues[index].field || ''" @input="updateValue(index, 'field', $event)" style="width: 100%;" required>
                  <option v-for="field in (subcollections[additionalValues[index].source]?.fields ?? [])" :key="field" :value="field">{{ field }}</option>
                </select>
              </label>

              <br><br>

              <!-- friendlyName -->
              <label class="font-bold">
                Friendly Name: <span v-if="errors[index]" class="font-normal text-red-600">{{ errors[index] }}</span>
                <br>
                <input type="text" :value="additionalValues[index].friendlyName || ''" @input="updateValue(index, 'friendlyName', $event)" />
              </label>

              <br><br>
              <div class="flex gap-2">

                <!-- unit -->
                <label class="font-bold w-[100px]">
                  Unit:
                  <br>
                  <input type="text" :value="subcollections[additionalValues[index].source]?.unit ?? additionalValues[index].unit ?? ''" @input="updateValue(index, 'unit', $event)" />
                </label>

                <br><br>

                <!-- color -->
                <label class="font-bold w-[50px]">
                  Color:
                  <br>
                  <input type="color" :value="additionalValues[index].color || '#FF8800'" @input="updateValue(index, 'color', $event)" required class="h-[45px]"/>
                </label>
              </div>
            </div>

            <!-- Calculation options -->
            <div v-else>
              <!-- Source -->
              <label class="font-bold">
                Source:
                <select v-model="additionalValues[index].source" style="width: 100%;" @change="getFieldOptions(index, $event)" required>
                  <option v-for="subcollection in Object.keys(subcollections)" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
                </select>
              </label>

              <br><br>

              <label class="font-bold relative">
                Values: <span v-if="!subcollections[additionalValues[index].source]?.fields" class="font-normal text-red-600">(select source)</span>
                <br>

                <div v-for="(value, valueIndex) in additionalValues[index].variables" :key="valueIndex" class="flex gap-1 items-center">
                  <div class="font-bold text-xs hover:cursor-pointer text-red-500" @click="additionalValues[index].variables.splice(valueIndex, 1)">X</div>
                  <p class="font-normal text-black">{{ value }}</p>
                </div>
              </label>
              <div>
                <SimpleDropdown
                  :buttonString="'+ Add Value'"
                  :addIcon="false"
                  :dropdownItems="subcollections[additionalValues[index].source]?.fields ?? []" 
                  @item-clicked="saveValue(index, $event)"
                />
              </div>

              <br>

              <!-- Equation -->
              <label class="font-bold">
                Equation:
                <br>
                <input type="text" :value="additionalValues[index].formula || ''" @input="updateValue(index, 'formula', $event)" required />
              </label>

              <br><br>

              <!-- friendlyName -->
              <label class="font-bold">
                Friendly Name: <span v-if="errors[index]" class="font-normal text-red-600">{{ errors[index] }}</span>
                <br>
                <input type="text" :value="additionalValues[index].friendlyName || ''" @input="updateValue(index, 'friendlyName', $event)" required />
              </label>

              <br><br>
              <div class="flex gap-2">

                <!-- unit -->
                <label class="font-bold w-[100px]">
                  Unit:
                  <br>
                  <input type="text" :value="subcollections[additionalValues[index].source]?.unit ?? additionalValues[index].unit ?? ''" @input="updateValue(index, 'unit', $event)" />
                </label>

                <br><br>

                <!-- color -->
                <label class="font-bold w-[50px]">
                  Color:
                  <br>
                  <input type="color" :value="additionalValues[index].color || '#FF8800'" @input="updateValue(index, 'color', $event)" required class="h-[45px]"/>
                </label>
              </div>
            </div>
          </div>
        </div>
        <button type="button" @click="addValue" class="text-color-a font-bold">+ Add Value</button>

        <!-- Inputs for other widgets -->
        
        <br><br>
        <div class="flex justify-between">
          <button type="button" @click="close" class="default-button">Close</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          <button v-else type="submit" :disabled="loading || errors.length > 0" class="default-button" :class="{'default-button-off': errors.length > 0 || loading}">Save</button>
        </div>
      </form>
    </div>
    <div id="preview">

    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue';
import { TrashIcon } from '@heroicons/vue/24/solid';

import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
import SimpleDropdown from '../dropdowns/SimpleDropdown.vue';

export default {
  name: 'UpdateTemplate',
  components: {
    LoadingAnimation1,
    TrashIcon,
    SimpleDropdown
  },
  props: {
    dataID: String,
    index: {
      type: Number,
      default: null
    },
    uid: String,
    page: String,
    templateID: String,
    widget: {
      type: String,
      default: null
    },
    topic: {
      type: String,
      default: null
    },
    subcollection: {
      type: String,
      default: null
    },
    values: {
      type: Array,
      default: () => [{calculation: false}]
    },
  },
  // emits: ['widget', 'close', 'error'],
  setup(props, { emit }) {
    const type = ref(props.widget);
    const title = ref(props.topic);
    const additionalValues = ref(props.values);
    const loading = ref(false);
    const subcollections = reactive({});
    const fields = ref([]);
    const setCalculation = ref(false);
    const errors = ref([]);

    onMounted(async() => {
      const body = {
        uid: props.uid,
        dataID: [props.dataID]
      }

      try {
        const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/device_requests', {
          method: 'POST',
          headers: {
            'content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        const responseData = await response.json();
        for (const subcollection of responseData.details[props.dataID].subcollections) {
          subcollections[subcollection] = {"fields": [], "unit": ""};
        }

      } catch (error) {
        console.log(error);
      }
      
      if (additionalValues.value[0].source) {
        let index = 0;
        for (const value of additionalValues.value) {
          getFieldOptions(index, { target: { value: value.source } });
          index++;   
        }
      }
    });


    const getFieldOptions = async(index, event) => {
      // add source to additionalValues
      additionalValues.value[index].source = event.target.value;

      if (subcollections[event.target.value]?.fields.length) {
        return;
      }

      const body = {
        uid: props.uid,
        dataID: props.dataID,
        subcollection: event.target.value,
        limit: 1
      }

      try {
        const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/device_requests/data', {
          method: 'POST',
          headers: {
            'content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        const responseData = await response.json();

        // Grab the unit
        subcollections[event.target.value].unit = responseData.details[0].unit;
        additionalValues.value[index].unit = responseData.details[0].unit;
        if (!additionalValues.value[index].color) {
          additionalValues.value[index].color = "#FF8800";
        }

        // Remove irrelevant fields
        delete responseData.details[0].unit;
        delete responseData.details[0].messageUTC;
        delete responseData.details[0].receiveUTC;

        // Add remaining fields
        subcollections[event.target.value].fields = Object.keys(responseData.details[0]);

      } catch (error) {
        console.log(error);
      }
    };

    const saveValue = (index, value) => {
      if (!additionalValues.value[index].variables) {
        additionalValues.value[index].variables = [];
      }
      additionalValues.value[index].variables.push(value);
    }

    const addValue = () => {
      additionalValues.value.push({
        calculation: false,
        color: '#FF8800'
      });
    };

    const updateValue = (index, inputType, event) => {
      if (inputType === 'friendlyName') {
        // Check if friendlyName already exists in additionalValues
        const exists = additionalValues.value.some((value, i) => 
          i !== index && value.friendlyName === event.target.value
        );
        if (exists) {
          // Make sure errors array is at least as long as the index
          while (errors.value.length <= index) {
            errors.value.push(null);
          }
          errors.value[index] = 'Friendly name exists';
        } else {
          errors.value[index] = null;
          // If all errors are null, empty the array
          if (errors.value.every(error => error === null)) {
            errors.value = [];
          }
        }
      }
      additionalValues.value[index][inputType] = event.target.value;
    };

    const removeValue = (index) => {
      additionalValues.value = additionalValues.value.filter((_, i) => i !== index);
    };

    const handleSubmit = async() => {
      loading.value = true;
      const body = {
        uid: props.uid,
        templateID: props.templateID,
        dashboard: props.page,
        widgetType: props.widget,
        widgetData: {
          title: title.value,
          type: "timeseries",
          specifications: {}
        }
      }

      if (props.index !== null) {
        body.widgetIndex = props.index;
      }
      
      for (const data of additionalValues.value) {
        // grab the source
        const source = data.source;
        delete data.source;

        // Set up firendly name if one was not provided
        if (!data.friendlyName) {
          data.friendlyName = data.field;
        }

        // Set up the specifications
        if (!body.widgetData.specifications[source]) {  
          body.widgetData.specifications[source] = [];
        }

        // Add the data to the specifications
        body.widgetData.specifications[source].push(data);
      }
      
      let responseData;

      try {
          const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/templates', {
              method: 'PUT',
              headers: {
                  'content-Type': 'application/json'
              },
              body: JSON.stringify(body)
          });
          responseData = await response.json();
      } catch (error) {
          console.log(error);
      }

      if (responseData.code === 200) {
        emit('widget', body.widgetData);
      } else {
          console.log(responseData);
          emit('error', responseData.message);
      }
      loading.value = false;
      close();
    };

    const close = () => {
      emit('close');
    };

    return {
      type,
      title,
      additionalValues,
      loading,
      subcollections,
      fields,
      handleSubmit,
      getFieldOptions,
      addValue,
      updateValue,
      removeValue,
      close,
      setCalculation,
      saveValue,
      errors
    };
  }
};
</script>

<style scoped>

input {
  width: 100%;
}
</style>
