<template>
  <div class="h-screen w-screen bg-color-c dark:bg-color-c-darkmode">
    <DefaultHeader></DefaultHeader>
    <div class="flex">
      <DefaultSidebar></DefaultSidebar>
      <div class=" pt-[10px] ml-[70px] w-full">

        <div class="flex justify-between h-fit mb-[10px] bg-color-e-darker" :class="[pageSubtitle ? 'h-[100px]' : 'h-[65px]']">

          <!--Create the page title and subtitle-->
          <div class="inline-block">
            <h1 class="text-3xl font-bold m-[10px]">{{ pageTitle }}</h1>
            <div v-if="pageSubtitle" v-html="pageSubtitle" class="ml-[10px]"></div>
          </div>

          <!--Create links to subpages-->
          <div v-if="subpageLinks" class="self-end mr-20 mb-[6px]">
            <a v-for="[pageName, address] in subpageLinks" :key="pageName" :href="address[0]" :class="[address[1] !== 'inactive' ? 'bg-color-c-darker' : 'bg-color-c cursor-default dark:bg-color-c-darkmode dark:text-dark-text']" class="p-2 ml-2 rounded-t-lg">{{ pageName }}</a>
            <!-- <a :href="deviceSettingsLink" class="p-2 px-3 ml-2 rounded-t-lg bg-color-c-darker cursor-pointer">
              <img src="../assets/gear_black.png" alt="Settings" class="w-5 h-5 inline-block align-middle">
            </a> -->
          </div>

        </div>

        <main :style="[pageSubtitle ? 'height: calc(100vh - 193px);' : 'height: calc(100vh - 168px);']" class=" overflow-y-auto">
          <!-- Main content will go here -->
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>
  
<script>
  import DefaultHeader from './Header.vue'
  import DefaultSidebar from './Sidebar.vue'
  // import { CogIcon } from '@heroicons/vue/20/solid'
  
  export default {
    name: 'DefaultLayout',
    components: {
      DefaultHeader,
      DefaultSidebar,
      // CogIcon
    },
    props: {
      pageTitle: String,
      pageSubtitle: String,
      subpageLinks: Map
    },
    computed: {
      deviceSettingsLink() {
        console.log(this.$route.path + '/settings');
        return this.$route.path + '/settings';
      }
    }
  };
</script>