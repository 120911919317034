<template>
  <div id="app">
    <router-view></router-view> <!-- This is where routed components are displayed -->
  </div>
</template>

<script>
// import { onAuthStateChanged } from '@/firebase';

export default {
  name: 'App',
}
</script>

<style>
</style>
