function localToUTC(localTimeString) {
    if (!localTimeString) {
        return null;
    }

    // Create a new Date objects from the local date and time strings
    const localDate = new Date(localTimeString);

    // Get the UTC date and time string with toISOString() methoud
    const utcDateTime = localDate.toISOString();

    // Extract the UTC date and time components and combine them in
    //   the proper format
    const utcDate = utcDateTime.slice(0, 10);
    const utcTime = utcDateTime.slice(11, 19);
    const utcDateString = utcDate + ' ' + utcTime;

    return utcDateString;
}

function utcToLocal(utcTimeString, format) {
    if (!utcTimeString) {
        return null;
    }

    // Create a new Date objects from the local date and time strings
    const utcDate = new Date(utcTimeString);

    const timeZoneOffsetMinutes = new Date().getTimezoneOffset();

    const localDate = new Date(utcDate.getTime() - timeZoneOffsetMinutes * 60 * 1000);

    // Get the UTC date and time string with toISOString() methoud
    if (format === '12hour') {
        // Get the components of the date
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        const day = String(localDate.getDate()).padStart(2, '0');
        let hours = localDate.getHours();
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM/PM
        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

        // Construct the formatted date string
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
    } else {
        // Get the components of the date
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');

        // Construct the formatted date string
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
}

function timeSimplified(timeString) {
    const timeStamp = new Date(timeString);
    const currentTime = new Date();
    const timeDifference = currentTime - timeStamp;

    // Less than 10 minutes = Current
    if (timeDifference < (10*60*1000)) {
        return "Current";
    } 
    
    // Less than 1 hour = incrament by 5 min
    else if (timeDifference < (60*60*1000)) {
        var minutes = timeDifference / (1000*60);
        return (Math.round(minutes/5)*5) + " minutes ago";
    } 
    
    // Less than 24 hours = incrament by hours
    else if (timeDifference < (24*60*60*1000)) {
    var hours = Math.round(timeDifference / (1000*60*60));
        if (hours > 1) {
            return hours + " hours ago";
        } else {
            return hours + " hour ago";
        }
    }

    // Less than 8 days = incrament by hours
    else if (timeDifference < (8*24*60*60*1000)) {
    var days = Math.round(timeDifference / (1000*60*60*24));
        if (days > 1) {
            return days + " days ago";
        } else {
            return days + " day ago";
        }
    }

    else{
        return timeString;
    }
}

function getPastTimestamp(hours, timeZone = 'utc') {
    const currentTimeUTC = new Date();
    const pastTimestampUTC = new Date(currentTimeUTC.getTime() - (hours * 60 * 60 * 1000));
    const pastUtcString = pastTimestampUTC.toISOString();

    const pastUtcDate = pastUtcString.slice(0, 10);
    const pastUtcTime = pastUtcString.slice(11, 19);

    if (timeZone !== 'utc') {
        return utcToLocal(pastUtcDate + ' ' + pastUtcTime, '24hour');
    } else {
        return pastUtcDate + ' ' + pastUtcTime;
    }
}

const dateToString = (date) => {
    return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ':' + String(date.getSeconds()).padStart(2, '0');
}

function findDifference(startTimeStamp, endTimeStamp) {
    // Parse the timestamp string
    const startDate = new Date(startTimeStamp);
    const endDate = new Date(endTimeStamp);

    // Grab the time difference in seconds, minutes, and hours. 
    const totalMilliseconds = endDate - startDate;
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    // seperate the difference into days, hours, minutes, and seconds. 
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    // format with leading zeros if necessary
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    // return the difference in "d Days HH:mm:ss"
    return [`${days} day(s) ${formattedHours}:${formattedMinutes}:${formattedSeconds}`, totalSeconds];
}

export { localToUTC, utcToLocal, timeSimplified, getPastTimestamp, findDifference, dateToString };