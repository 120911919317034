<template>
    <DefaultLayout
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    :subpageLinks="subpageLinks"
    >
        <!-- <button @click="createEvent = true">+ New Table</button> -->
        <div class="unit-events-body">
            <div v-for="(table, index) in tables" :key="index" style="width: 99%; margin-top: 20px; margin-bottom: 40px;">
                <EventsTable v-if="table.type === 'event'"
                    :dataID="dataID"
                    :tableIndex="index"
                    :template="table"
                    :templateID="templateID"
                    :timeFormat="timeFormat"
                    @tableDeleted="deleteTable"
                ></EventsTable>
            </div>
        </div>
        <button @click="createEvent = true" class="default-button m-5">+ Add Event Table</button>
        <CreateEvent v-if="createEvent" @close="createEvent = false" @submitForm="createEventTable"
            :dataID="dataID"
            :uid="uid"
            >
        </CreateEvent>
  </DefaultLayout>
</template>
  
<script>
  import { useStore } from 'vuex';
  import { onMounted, ref } from 'vue';
  import { router } from '@/router';
  import { doc, getDoc, onSnapshot } from 'firebase/firestore';
  import { db } from '@/firebase';
  import { utcToLocal, timeSimplified } from '@/tools/TimeFunctions';

  import DefaultLayout from '@/layouts/Layout.vue';
  import EventsTable from '@/components/tables/EventsTable.vue';
  import CreateEvent from '@/components/modals/CreateEvent.vue';
  
  export default {
      name: 'UnitDashboardEvents',
      components: {
          DefaultLayout,
          EventsTable,
          CreateEvent
      },
      setup() {
          // Set up reference variables.
          const pageTitle = ref('');
          const pageSubtitle = ref('');
          const subpageLinks = ref(new Map());
          const tables = ref(new Object());
          const createEvent = ref(false);
          const templateID = ref(null)

          // Set up all other variables
          const store = useStore();
          const user = store.state.user;
          const uid = user.uid;
          const timeFormat = user.timeFormat;
          const dataID = router.currentRoute.value.params.dataID;
          let unitDetails;

          onMounted(async () => {
              await getUnitDetails();
              getTemplate();
              checkActivity();
          });

          /**
           * Grabs the unit details and sets up the page title
           */
           const getUnitDetails = async() => {
              
              // Attempt the data document using the data ID
              try {
                  const docRef = doc(db, "data", dataID);
                  const docSnap = await getDoc(docRef);

                  if (docSnap.exists()) {
                      unitDetails = docSnap.data();
                      templateID.value = unitDetails.defaultTemplate;
                  }
              } catch(error) {
                  console.log(error)
                  pageTitle.value = dataID;
                  pageSubtitle.value = "Faild to retrieve data";
              }
          };

          /**
           * Grabs the template for the unit and adds additional subpage links
           */
            const getTemplate = async() => {

                // The template is not stored so grab it from the database.
                try {
                    const docRef = doc(db, "templates", unitDetails.defaultTemplate);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Grab the document data and store it
                        const templateData = docSnap.data();

                        // Grab the statistics.
                        tables.value = templateData.events.tables;

                        // Add any other additional subpage links
                        subpageLinks.value.set('Overview', [`/dashboard/${dataID}`]);

                        if (templateData.timeSeries) {
                            subpageLinks.value.set('Time Series', [`/dashboard/${dataID}/time-series`]);
                        }

                        if (templateData.events) {
                            subpageLinks.value.set('Events', [null, 'inactive']);
                        }

                    }
                } catch(error) {
                    console.log(error);
                }          
            };

            const checkActivity = () => {
                // Start a snapshot listener
                onSnapshot(doc(db, "data", dataID), (document) => {
                    const unitDetails = document.data();

                    if (unitDetails.friendlyName !== "") {
                        pageTitle.value = unitDetails.friendlyName;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold">Data ID: </span>
                            ${dataID}
                            <span style="font-weight: bold; margin-left: 20px;"> Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    
                    } else {
                        pageTitle.value = dataID;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold;">Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    }
                });
            };

            const createEventTable = async(tableData) => {
                console.log(tableData);

                const body = {
                    uid: uid,
                    templateID: unitDetails.defaultTemplate,
                    dashboard: 'events',
                    widgetType: 'tables',
                    widgetData: tableData,
                };

                let responseData;

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/templates', {
                        method: 'PUT',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                createEvent.value = false;
                setTimeout(() => {
                    if (responseData.code === 200) {
                        tables.value.push(tableData);
                    } else {
                        console.log(responseData);
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when creating the table");
                        }
                    }
                }, 100);
            };

            const deleteTable = (index) => {
                tables.value.splice(index, 1);
            };

            return {
                pageTitle,
                pageSubtitle,
                subpageLinks,
                dataID,
                templateID,
                uid,
                timeFormat,
                tables,
                createEvent,
                createEventTable,
                deleteTable,
            }
        }
    };
</script>
  
<style>

  .unit-events-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
  }

</style>