<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <h2 class=" text-2xl mb-3">New Entity</h2>
      <form @submit.prevent="handleSubmit">
        <label>
          Entity Name:
          <br>
          <input v-model="name" type="text" required />
        </label>
        <br><br>
        <label>
          Entity URL:
          <br>
          <input v-model="url" type="text" />
        </label>
        <br><br>
        <label>
          Entity Logo:
          <br>
          <input type="file" accept="image/*" @change="setFile('logo', $event)" />
        </label>
        <br><br>
        <label>
          Entity Icon:
          <br>
          <input type="file" accept="image/*" @change="setFile('icon', $event)" />
        </label>
        <br><br>
        <div class="flex justify-between">
          <button @click="close" class="default-button">Close</button>
          <button type="submit" :disabled="loading" class="default-button">Create</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

export default {
  name: 'CreateEntity',
  components: {
    LoadingAnimation1
  },
  setup(_, { emit }) {
    const name = ref('');
    const logoFile = ref(null);
    const iconFile = ref(null);
    const url = ref('');
    const loading = ref(false);

    const handleSubmit = () => {
      loading.value = true;
      const formData = {name: name.value, logo: logoFile.value, icon: iconFile.value, url:url.value};
      emit('submitForm', formData);
    };

    const setFile = (type, event) => {
      const file = event.target.files[0];
      if (file) {
        if (type === 'logo') {
          logoFile.value = file
        }
        else if (type === 'icon') {
          iconFile.value = file
        }
      }
    };

    const close = () => {
      emit('close');
    };

    return {
      name, 
      url,
      handleSubmit,
      setFile,
      close,
      loading
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
}

input {
  width: 100%;
}
</style>