<template>
    <div class="bg-white rounded-lg p-[15px] m-2 w-fit">
        <VuePlotly
            :key="key"
            :data="data"
            :layout="layout"
            :config="config"
        ></VuePlotly>
        <p class="text-sm">{{ lastUpdate }}</p>
    </div>
</template>
  
<script>
    import { ref, onMounted } from 'vue';
    import { VuePlotly } from 'vue3-plotly';
    import { utcToLocal } from '@/tools/TimeFunctions';
    import { collection, query, orderBy, limit, onSnapshot, getDocs} from 'firebase/firestore';
    import { db } from '@/firebase';
  
    export default {
        name: 'GenericGauge',
        components: {
            VuePlotly
        },
        props: {
            template: Object,
            dataID: String,
        },
        setup(props) {
            const interval = ((props.template.range[1] - props.template.range[0]) / (6));
            const key = ref(0);
            const lastUpdate = ref('Last Update:')
            const data = ref([
                {
                    type: "indicator",
                    mode: "gauge+number",
                    value: null,
                    number: {
                        prefix: ``,
                        suffix: `<span style="font-size: 25px;">${props.template.unit || ''}</span> `,
                        font: { size: 40 },
                        valueformat: props.template.valuePrecision,
                    },
                    title: { text: props.template.title, font: { size: 24 } },
                    gauge: {
                        axis: { 
                            range: null,
                            tickwidth: 1,
                            tickcolor: "black"
                        },
                        bar: { color: props.template.color },
                        bgcolor: `${props.template.bgColor || 'white'}`,
                        borderwidth: 2,
                        bordercolor: "gray",
                        steps: []
                    }
                }
            ]);
            const layout = ref({
                width: 300,
                height: 200,
                margin: { t: 60, r: 20, l: 20, b: 20 },
                paper_bgcolor: "white",
                font: { color: "black", family: "Arial"}
            });
            const config = ref({displaylogo: false})

            onMounted(() => {
                // set Prefex
                if (props.template.prefix) {
                    data.value[0].number.prefix = `<span style="font-size: 15px;">${props.template.prefix}</span><br>`;
                }

                // Set the range
                if (!Array.isArray(props.template.range)) {
                    console.error('Invalid range object. Expected "min" and "max" properties.');
                } else {
                    data.value[0].gauge.axis.range = props.template.range;
                }

                // Set the steps
                if (props.template.steps) {
                    let index = 0;
                    props.template.steps.forEach((step) => {
                        if (!Array.isArray(step.range)) {
                            data.value[0].gauge.steps[index] = {
                                range: [null, null],
                                color: step.color,
                                thickness: `${step.thickness || 1}`
                            }
                            setRange(step.range, index);
                        }
                        else {
                            data.value[0].gauge.steps[index] = {
                                range: step.range,
                                color: step.color,
                                thickness: `${step.thickness || 1}`
                            }
                        }
                        index++;
                    });
                }

                // Set tick Intervals
                if (interval < 1) {
                    data.value[0].gauge.axis.tickvals = [
                        props.template.range[0],
                        (props.template.range[0] + interval).toFixed(2),
                        (props.template.range[0] + interval*2).toFixed(2),
                        (props.template.range[0] + interval*3).toFixed(2),
                        (props.template.range[0] + interval*4).toFixed(2),
                        (props.template.range[0] + interval*5).toFixed(2),
                        props.template.range[1]
                    ]
                }
                else {
                    data.value[0].gauge.axis.tickvals = [
                        props.template.range[0],
                        Math.round(props.template.range[0] + interval),
                        Math.round(props.template.range[0] + interval*2),
                        Math.round(props.template.range[0] + interval*3),
                        Math.round(props.template.range[0] + interval*4),
                        Math.round(props.template.range[0] + interval*5),
                        props.template.range[1]
                    ]
                }

                // Set delta
                if (props.template.delta) {
                    data.value[0].mode = "gauge+number+delta";
                    // data.value[0].set('delta', props.template.delta);
                    data.value[0]['delta'] = props.template.delta;
                }

                // Set threshold
                if (props.template.threshold) {
                    const threshold = {
                        line: { color: "black", width: 4 },
                        thickness: 1,
                        value: props.template.threshold
                    };
                    data.value[0].gauge["threshold"] = threshold;
                }

                // Get last update
                getLastUpdate(props.template.source);

                // Set snapshots
                setSnapshots(props.template.source);
            });

            const getLastUpdate = async(subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy("messageUTC", "desc"), limit(1));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    lastUpdate.value = `Last Update: ${utcToLocal(docData.messageUTC, '12hour')}`;
                    if (props.template.field) {
                        data.value[0].value = docData[props.template.field].toFixed(2);
                    }
                    else if (props.template.calculate) {
                        const { formula, denominator} = props.template.calculate;
                        const variables = {};
                        denominator.forEach((field) => {
                            variables[field] = docData[field];
                        });
                        const calculateFn = new Function(...denominator, `return ${formula}`);
                        data.value[0].value = calculateFn(...Object.values(variables)).toFixed(2);
                    }
                });
            };

            /**
             * Grab data from a subcollection in the database
             */
            const setSnapshots = (subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy('messageUTC', 'desc'), limit(1));
                onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const docData = change.doc.data();
                        lastUpdate.value = `Last Update: ${utcToLocal(docData.messageUTC, '12hour')}`;
                        if (props.template.field) {
                            data.value[0].value = docData[props.template.field].toFixed(2);
                        }
                        else if (props.template.calculate) {
                            const { formula, denominator} = props.template.calculate;

                            const variables = {};
                            denominator.forEach((field) => {
                                variables[field] = docData[field];
                            });
                            const calculateFn = new Function(...denominator, `return ${formula}`);
                            data.value[0].value = calculateFn(...Object.values(variables)).toFixed(2);
                        }
                    });
                    key.value += 1;
                });
            };

            const setRange = (range, index) => {
                const minRef = collection(db, 'data', props.dataID, range.min.source);
                const maxRef = collection(db, 'data', props.dataID, range.max.source);

                const minQuery = query(minRef, orderBy('messageUTC', 'desc'), limit(1));
                const maxQuery = query(maxRef, orderBy('messageUTC', 'desc'), limit(1));

                onSnapshot(minQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const docData = change.doc.data();
                        if (range.min.field) {
                            data.value[0].gauge.steps[index].range[0] = docData[range.min.field];
                        }
                        else if (range.min.calculate) {
                            const { formula, denominator} = range.min.calculate;

                            const variables = {};
                            denominator.forEach((field) => {
                                variables[field] = docData[field];
                            });
                            const calculateFn = new Function(...denominator, `return ${formula}`);
                            data.value[0].gauge.steps[index].range[0] = calculateFn(...Object.values(variables)).toFixed(2);
                        }
                    });
                    key.value += 1;
                });

                onSnapshot(maxQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const docData = change.doc.data();
                        if (range.max.field) {
                            data.value[0].gauge.steps[index].range[1] = docData[range.max.field];
                        }
                        else if (range.max.calculate) {
                            const { formula, denominator} = range.max.calculate;

                            const variables = {};
                            denominator.forEach((field) => {
                                variables[field] = docData[field];
                            });
                            const calculateFn = new Function(...denominator, `return ${formula}`);
                            data.value[0].gauge.steps[index].range[1] = calculateFn(...Object.values(variables)).toFixed(2);
                        }
                    });
                    key.value += 1;
                });
            };
        
            return {
                data,
                layout,
                key,
                config,
                lastUpdate
            };
        },
    };
</script>

<style>
</style>